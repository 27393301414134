import React, { useEffect, useRef, useState } from 'react';
import { Col, Row, Card, Container } from 'react-bootstrap';
import Skeleton from '@material-ui/lab/Skeleton';
import './homeSelection.scss'
import SelectionCard from './selectionCard';

export default function HomeSelections(props) {

    const scrollRef = useRef(null);
    const [indexFocus, setIndexFocus] = useState(0);
    const [isInitialLoad, setIsInitialLoad] = useState(false);
    const [items, setItems] = useState([
        <Col md={12} lg={4}>
            <Card className='selection-container'>
                <Skeleton animation='wave' variant='rect' style={{ width: '100%', height: window.innerWidth > 990 ? '40vh' : '15vh'}}/>
            </Card>
        </Col>
        ,
        <Col md={12} lg={4}>
            <Card className='selection-container'>
                <Skeleton animation='wave' variant='rect' style={{ width: '100%', height: window.innerWidth > 990 ? '40vh' : '15vh' }} />
            </Card>
        </Col>
        ,
        <Col md={12} lg={4}>
            <Card className='selection-container'>
                <Skeleton animation='wave' variant='rect' style={{ width: '100%', height: window.innerWidth > 990 ? '40vh' : '15vh' }} />
            </Card>
        </Col>
    ]);

    useEffect(() => {
        setIsInitialLoad(true);
        if(isInitialLoad){
            const item = scrollRef.current.children[indexFocus];
            if(item){
                item.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
            } 
        }
      }, [indexFocus]);

    const handlePrev = () => {
        setIndexFocus((prevIndex) => (prevIndex - 1 < 0 ? 0 : prevIndex - 1));
    };

    const handleNext = () => {
        setIndexFocus((prevIndex) => (prevIndex + 1 >= props.apartments.length - 1 ? prevIndex : prevIndex + 1));
    };

    function getItems() {
        if (props.apartments) {
            return props.apartments.map((apart, index) => (
                <SelectionCard apartment={apart} className={`object-item`} />
            ))
        } else {
            return items.map(el => el);
        }
    }

    return (
        <>
            <div className="noPadding mt-5">
                <h2 className="homepage-title">NOTRE SÉLECTION DE LOGEMENTS</h2>
                <div className="separator"></div>
                <Container style={{height: 'unset', padding: 'unset'}}>
                    <div className="scroll-container" ref={scrollRef}>
                        {
                            getItems()
                        }
                    </div>
                    <Row className="d-flex justify-content-center align-items-center mb-5 noPadding">
                        <Col xs={6} className="d-flex justify-content-center align-items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="71" height="74" viewBox="0 0 26.519 27.5" className="arrowHome" onClick={handlePrev}>
                                <g id="Flèchegauche" transform="translate(-138 -1637)">
                                    <rect id="Rectangle_28" data-name="Rectangle 28" width="26.385" height="27.5" transform="translate(138 1637)" fill="#b7a57e" />
                                    <path id="Icon_awesome-long-arrow-alt-left" data-name="Icon awesome-long-arrow-alt-left" d="M3.5,14.18h7.89a.314.314,0,0,0,.314-.314V12.4a.314.314,0,0,0-.314-.314H3.5v-1.2a.627.627,0,0,0-1.071-.443L.184,12.692a.627.627,0,0,0,0,.887l2.249,2.249A.627.627,0,0,0,3.5,15.384Z" transform="translate(152.813 1637.615)" fill="#fff" />
                                </g>
                            </svg>
                        </Col>
                        <Col xs={6} className="d-flex justify-content-center align-items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="71" height="74" viewBox="0 0 26.519 27.5" className="arrowHome" onClick={handleNext}>
                                <g id="Flèchedroite" transform="translate(164.519 1664.5) rotate(180)">
                                    <rect id="Rectangle_28" data-name="Rectangle 28" width="26.385" height="27.5" transform="translate(138 1637)" fill="#b7a57e" />
                                    <path id="Icon_awesome-long-arrow-alt-left" data-name="Icon awesome-long-arrow-alt-left" d="M3.5,14.18h7.89a.314.314,0,0,0,.314-.314V12.4a.314.314,0,0,0-.314-.314H3.5v-1.2a.627.627,0,0,0-1.071-.443L.184,12.692a.627.627,0,0,0,0,.887l2.249,2.249A.627.627,0,0,0,3.5,15.384Z" transform="translate(152.813 1637.615)" fill="#fff" />
                                </g>
                            </svg>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

