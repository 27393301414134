import React, { useState } from 'react';
import { Card, Col, Row, Badge } from 'react-bootstrap'
import '../bookingTab.scss'
import BookingDetails from '../bookingDetailForTenant/bookingDetails';
import { useDispatch } from 'react-redux';

export default function BookingCard(props) {

    const [booking, setBooking] = useState(props.booking);

    const [role, setRole] = useState(props.role);
    const dispatch = useDispatch();

    function handleChange(booking) {
        setBooking(booking);
    }
    function returnBadgeState() {
        const stateColor = { ['trop perçu']: 'danger', 'annulé': 'danger', ['à payer']: 'danger', payé: 'success', ['acompte versé']: 'secondary', ['en attente']: 'dark' }

        let color = stateColor[booking.state];
        let state = booking.state;

        if (!booking.is_confirmed && booking.state !== 'annulé') {
            color = 'warning';
            state = 'attente validation';
        }
        return <Badge className="apartmentText verticalCenteredBadge" variant={color}>{state}</Badge>
    }

    const beginDate = new Date(booking.begin_date_hour.replace(/\s/, 'T'));
    const endDate = new Date(booking.end_date_hour.replace(/\s/, 'T'));
    return (
        <Card className='mb-3'>
            <Card.Header>
                <Row className='noPadding d-flex justify-content-around align-items-center'>
                    <div className='mb-2' >
                        <BookingDetails booking={booking} cardOnChange={handleChange} role={props.role}></BookingDetails>
                    </div>
                    <div className='mb-2'>
                        <p className="apartmentRef"> {booking.apartment.reference}</p>
                    </div>
                    {booking.site_tiers &&
                        <div className='mb-2' >
                            <Badge variant={'info'}>{booking.site_tiers}</Badge>
                        </div>}
                    <Col xs={12} md={3} className="d-flex justify-content-center mb-2" >
                        {returnBadgeState()}
                    </Col>
                    <Col xs={12} md={3} className="d-flex justify-content-center mb-2">
                        {booking.bail.is_payed ?
                            <Badge variant='success'>Caution payé</Badge>
                            :
                            <Badge variant='danger'>Caution à payer</Badge>
                        }
                    </Col>
                </Row>
            </Card.Header>
            <Card.Body>
                <Row>
                    <Col xs={12} sm={6} md={5} lg={6}>
                        <p className="apartmentText text-center text-md-left">Référence : <strong>{booking.reference}</strong></p>
                        <p className="apartmentText text-center text-md-left">Locataire : {booking.contact.first_name + ' ' + booking.contact.last_name}</p>
                    </Col>
                    <Col xs={12} sm={6} md={4} lg={3}>
                        <p className="apartmentText text-center text-md-left">Début : {beginDate.toLocaleDateString()}</p>
                        <p className="apartmentText text-center text-md-left">Fin : {endDate.toLocaleDateString()}</p>
                    </Col>
                    <Col xs={12} md={3}>
                        <p className="apartmentText text-center text-md-left">Tarif : <strong>{(booking.rate).toFixed(2)} {booking.apartment.address.country === "SUISSE" || booking.apartment.address.country === "Suisse" ? 'CHF' : "€" }</strong></p>
                        <p className="apartmentText text-center text-md-left">Payé : <strong>{(booking.amount_allready_payed).toFixed(2)} {booking.apartment.address.country === "SUISSE" || booking.apartment.address.country === "Suisse" ? 'CHF' : "€" }</strong></p>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    )
}