import { useState } from 'react';
import { Row, Col, Button, Form } from 'react-bootstrap';
import instanceOfAxios from '../../../../../app/axios/axios';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import BookingDetails from '../../../booking/bookingDetails/bookingDetails';
import { useStore } from 'react-redux';
import Spinner from '../../../../spinner/Spinner';
import { useEffect } from 'react';
import {AiOutlineReload, AiOutlineSearch} from 'react-icons/ai';

export default function AccountingBooking(props) {
    const [pending, setPending] = useState(true);
    const [data, setData] = useState([]);
    const [params, setParams] = useState({ on_year: false });
    const [country, setCountry] = useState('FRANCE');
    const role = useStore().getState().header.role;
    const beginYear = new Date();
    const endYear = new Date();

    beginYear.setMonth(0);
    beginYear.setDate(1);

    endYear.setMonth(11);
    endYear.setDate(31);

    useEffect(() => {
        if (pending) {
            let query = new URLSearchParams(params).toString();
            instanceOfAxios.get('/admin/getCountingValues?' + query + `&&country=${country}`)
                .then(res => {
                    setData(Object.assign([], res.data.data));
                    setPending(false);
                })
                .catch(err => {
                    setPending(false);
                })
        }
    }, [pending])

    useEffect(() => {
        if(props.isActive){
            setPending(true);
            setData([]);
        }
    }, [props.isActive])

    function handleChangeToSwiss() {
        setCountry('SUISSE');
        setPending(true); // Recharger les données
    }

    function createCustomExportCSVButton(onClick) {
        return (
                <Button variant='secondary' onClick={onClick}>Exporter au format CSV</Button>
        );
    }

    function emailFormatter(cell, row) {
        return `<a href='mailto:${cell}'>${cell}</a>`;
    }
    
    function priceFormatter(cell, row) {
        return parseFloat(cell).toFixed(2).toString().replace('.', ',') + ' €';
    }

    function priceCommissionFormatter(cell, row) {
        return parseFloat(cell - row.tva_value).toFixed(2).toString().replace('.', ',') + ' €';
    }

    function dateFormatter(cell, row) {
        let date = new Date(cell.replace(/\s/, 'T'));
        var options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
        return date.toLocaleDateString('fr-CA', options);
    }

    function BookingDetailFormatter(cell, row) {
        return (
            <BookingDetails booking={row} role={role} />
        );
    }

    function handleChange(event) {
        params[event.target.name] = event.target.value;
        setParams(params);
    }

    function handleCheck(event) {
        params[event.target.name] = event.target.checked;
        setParams(params);
    }

    const options = {
        exportCSVBtn: createCustomExportCSVButton
    };

    return (
        <>
            <Row className='text-center mb-3 d-flex justify-content-center'>
                <Col xs={12} md={4}>
                    Du
                    <Form.Control type="date" name="begin_period" defaultValue={beginYear.toISOString().slice(0, 10)} onChange={(e) => handleChange(e)} />
                </Col>
                <Col xs={12} md={4}>
                    Au
                    <Form.Control type="date" name="end_period" defaultValue={endYear.toISOString().slice(0, 10)} onChange={(e) => handleChange(e)} />
                </Col>
                <Col xs={12} md={5}>
                    Réference de logement
                    <Form.Control type="text" name="apart_ref" placeholder="5***" onChange={(e) => handleChange(e)} />
                </Col>
                <Col xs={12} className='d-flex align-items-end justify-content-center'>
                    <Form.Group className='mb-0'>
                        <Form.Check className='d-flex align-items-center mb-0' type="checkbox" name="on_year" label="au bon de commande" onChange={(e) => handleCheck(e)} />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col md={12} className='text-center' style={{ marginBottom: "15px" }}>
                    {
                        pending ?
                            <Spinner />
                            :
                            <Button variant='secondary' className='whiteSVG' onClick={() => setPending(true)}>Rechercher <AiOutlineSearch/></Button>
                    }
                </Col>
            </Row>
            <Row>
                <Col md={12} className='text-center' style={{ marginBottom: "15px" }}>
                    {
                        pending ?
                            <Spinner />
                            :
                            <Button variant='secondary' className='whiteSVG' onClick={handleChangeToSwiss}>Suisse</Button>
                    }
                </Col>
            </Row>
            <BootstrapTable data={data} options={options} hover pagination exportCSV csvFileName='CA-réservation-class-appart.csv'>
                <TableHeaderColumn dataField='apartment_id' dataFormat={BookingDetailFormatter} width='90'>Détails</TableHeaderColumn>
                <TableHeaderColumn isKey dataField='created_at' dataFormat={dateFormatter} csvHeader='Date de création' csvFormat={dateFormatter} dataSort={true} width='180'>Réservé</TableHeaderColumn>
                <TableHeaderColumn dataField='begin_date_hour' dataFormat={dateFormatter} csvHeader='Date de début' csvFormat={dateFormatter} dataSort={true} width='180'>Début</TableHeaderColumn>
                <TableHeaderColumn dataField='end_date_hour' dataFormat={dateFormatter} csvHeader='Date de fin' csvFormat={dateFormatter} dataSort={true} width='180'>Fin</TableHeaderColumn>
                <TableHeaderColumn dataField='name' dataSort={true} csvHeader='Nom / Prénom' width='200'>Nom</TableHeaderColumn>
                <TableHeaderColumn dataField='email' dataSort={true} width='200' csvHeader='Email' dataFormat={emailFormatter}>Email</TableHeaderColumn>
                <TableHeaderColumn dataField='apart_ref' dataSort={true} csvHeader='Référence logement' width='100'>Ref logement</TableHeaderColumn>
                <TableHeaderColumn dataField='reference' dataSort={true} csvHeader='Référence de réservation' width='100'>Ref</TableHeaderColumn>
                <TableHeaderColumn dataField='ref_airbnb' dataSort={true} csvHeader='Référence site passerelle' width='150'>Ref passerelle</TableHeaderColumn>
                <TableHeaderColumn dataField='type' dataSort={true} csvHeader='Type logement' width='70'>Type</TableHeaderColumn>
                <TableHeaderColumn dataField='duration' dataSort={true} csvHeader='Durée' width='80'>Durée</TableHeaderColumn>
                <TableHeaderColumn dataField='nb_person' dataSort={true} csvHeader='Nb personnes' width='80'>PERS</TableHeaderColumn>
                <TableHeaderColumn dataField='nb_adult' dataSort={true} csvHeader='Nb adultes' width='100'>Adultes</TableHeaderColumn>
                <TableHeaderColumn dataField='rate' dataSort={true} csvHeader='Tarif' width='120' csvFormat={priceFormatter} dataFormat={priceFormatter}>Tarif</TableHeaderColumn>
                <TableHeaderColumn dataField='rent' dataSort={true} csvHeader='Loyer' width='120' csvFormat={priceFormatter} dataFormat={priceFormatter}>Loyer</TableHeaderColumn>
                <TableHeaderColumn dataField='EDF' dataSort={true} width='120' csvFormat={priceFormatter} dataFormat={priceFormatter}>EDF</TableHeaderColumn>
                <TableHeaderColumn dataField='assurance' dataSort={true} csvHeader='Assurance' width='120' csvFormat={priceFormatter} dataFormat={priceFormatter}>Assurance</TableHeaderColumn>
                <TableHeaderColumn dataField='commission' dataSort={true} csvHeader='Commission HT' width='170' csvFormat={priceFormatter} dataFormat={priceCommissionFormatter}>Commissions HT</TableHeaderColumn>
                <TableHeaderColumn dataField='tva_value' dataSort={true} csvHeader='TVA' width='120' csvFormat={priceFormatter} dataFormat={priceFormatter}>TVA</TableHeaderColumn>
                <TableHeaderColumn dataField='site_tiers_com' dataSort={true} csvHeader='Commission site tiers' width='200' csvFormat={priceFormatter} dataFormat={priceFormatter}>Commission site tier</TableHeaderColumn>
                <TableHeaderColumn dataField='site_tiers_dif' dataSort={true} csvHeader='Différence site tiers' width='200' csvFormat={priceFormatter} dataFormat={priceFormatter}>Différence site tiers</TableHeaderColumn>
                <TableHeaderColumn dataField='amount_allready_payed' dataSort={true} csvHeader='déjà payé' width='120' csvFormat={priceFormatter} dataFormat={priceFormatter}>Payé</TableHeaderColumn>
                <TableHeaderColumn dataField='diff' dataSort={true} csvHeader='A payer' width='120' csvFormat={priceFormatter} dataFormat={priceFormatter}>A payer</TableHeaderColumn>
            </BootstrapTable>
            <Row>
                <Col xs={12} md={6} className='text-center d-flex flex-column'>
                    <small>*PC : Prix coutant</small>
                    <small>*RP : Remboursement propriétaire</small>
                    <small>*FP : Facture prestataire</small>
                </Col>
                <Col xs={12} md={6} className='text-center d-flex flex-column'>
                    <small>*CA : Commission achat</small>
                    <small>*CS : Commission service</small>
                </Col>
            </Row>
        </>
    )
}