import { useState } from 'react';
import { Row, Col, Button, Form } from 'react-bootstrap';
import instanceOfAxios from '../../../../../app/axios/axios';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import BookingDetails from '../../../booking/bookingDetails/bookingDetails';
import { useStore } from 'react-redux';
import Spinner from '../../../../spinner/Spinner';
import { useEffect } from 'react';
import { AiOutlineSearch } from 'react-icons/ai';

export default function AccountingCaution(props) {
    const [pending, setPending] = useState(true);
    const [data, setData] = useState([]);
    const [params, setParams] = useState({ on_year: false });
    const role = useStore().getState().header.role;
    const beginYear = new Date();
    const endYear = new Date();

    beginYear.setMonth(0);
    beginYear.setDate(1);

    endYear.setMonth(11);
    endYear.setDate(31);

    useEffect(() => {
        if (pending) {
            let query = new URLSearchParams(params).toString();
            instanceOfAxios.get('/admin/getCountingValues?' + query)
                .then(res => {
                    setData(Object.assign([], res.data.data));
                    setPending(false);
                })
                .catch(err => {
                    setPending(false);
                })
        }
    }, [pending])

    useEffect(() => {
        if(props.isActive){
            setPending(true);
            setData([]);
        }
    }, [props.isActive])

    function createCustomExportCSVButton(onClick) {
        return (
                <Button variant='secondary' onClick={onClick}>Exporter au format CSV</Button>
        );
    }

    function bailFormatter(cell, row, formatExtraData, rowIdx, type) {
        let total = 0;
        cell.items.map(item => {
            switch (type) {
                case 'PC':
                    if (item.type == 'coutant') {
                        total += item.amount
                    }
                    break;
                case 'RP':
                    if (item.type == 'proprietaire') {
                        total += item.amount
                    }
                    break;
                case 'FP':
                    if (item.type == 'prestataire') {
                        total += item.amount
                    }
                    break;
                case 'CA':
                    if (item.type == 'achat') {
                        total += item.amount
                    }
                    break;
                case 'CS':
                    if (item.type == 'service') {
                        total += item.amount
                    }
                    break;
            }
        })
        return parseFloat(total).toFixed(2).toString().replace('.', ',') + ' €';
    }

    function priceFormatter(cell, row) {
        return parseFloat(cell).toFixed(2).toString().replace('.', ',') + ' €';
    }

    function dateFormatter(cell, row) {
        let date = new Date(cell.replace(/\s/, 'T'));
        var options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
        return date.toLocaleDateString('fr-CA', options);
    }

    function BookingDetailFormatter(cell, row) {
        return (
            <BookingDetails booking={row} role={role} />
        );
    }
    function BailPaymentTypeFormatter(cell, row) {
        return cell.payments[0] ? cell.payments[0].payment_mode : '';
    }

    function BailRefundAtFormatter(cell, row) {
        if(cell.refund_at){
            let date = new Date(cell.refund_at.replace(/\s/, 'T'));
            var options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
            return date.toLocaleDateString('fr-CA', options);
        }
    }

    function BailRetentionTotalFormatter(cell, row){
        let amount = cell.amount
        cell.items.map(item => amount -= item.amount);
        return parseFloat(amount).toFixed(2).toString().replace('.', ',') + ' €';
    }

    function BailAmountKeepFromFormatter(cell, row){
        let amount = 0
        cell.items.map(item => amount += item.amount);
        return parseFloat(amount).toFixed(2).toString().replace('.', ',') + ' €';
    }

    function BailRefundFromFormatter(cell, row) {
        return cell.refund_from;
    }

    function handleChange(event) {
        params[event.target.name] = event.target.value;
        setParams(params);
    }

    function handleCheck(event) {
        params[event.target.name] = event.target.checked;
        setParams(params);
    }

    const options = {
        exportCSVBtn: createCustomExportCSVButton
    };

    return (
        <>
            <Row className='text-center mb-3 d-flex justify-content-center'>
                <Col xs={12} md={4}>
                    Du
                    <Form.Control type="date" name="begin_period" defaultValue={beginYear.toISOString().slice(0, 10)} onChange={(e) => handleChange(e)} />
                </Col>
                <Col xs={12} md={4}>
                    Au
                    <Form.Control type="date" name="end_period" defaultValue={endYear.toISOString().slice(0, 10)} onChange={(e) => handleChange(e)} />
                </Col>
                <Col xs={12} md={5}>
                    Réference de logement
                    <Form.Control type="text" name="apart_ref" placeholder="5***" onChange={(e) => handleChange(e)} />
                </Col>
                <Col xs={12} className='d-flex align-items-end justify-content-center'>
                    <Form.Group className='mb-0'>
                        <Form.Check className='d-flex align-items-center mb-0' type="checkbox" name="on_year" label="au bon de commande" onChange={(e) => handleCheck(e)} />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col md={12} className='text-center' style={{ marginBottom: "15px" }}>
                    {
                        pending ?
                            <Spinner />
                            :
                            <Button variant='secondary' className='whiteSVG' onClick={() => setPending(true)}>Rechercher <AiOutlineSearch/></Button>
                    }
                </Col>
            </Row>
            <BootstrapTable data={data} options={options} hover pagination exportCSV csvFileName='CA-caution-class-appart.csv'>
                <TableHeaderColumn dataField='apartment_id' dataFormat={BookingDetailFormatter} width='90'>Détails</TableHeaderColumn>
                <TableHeaderColumn isKey dataField='created_at' dataFormat={dateFormatter} csvHeader='Date de création' csvFormat={dateFormatter} dataSort={true} width='180'>Réservé</TableHeaderColumn>
                <TableHeaderColumn dataField='begin_date_hour' dataFormat={dateFormatter} csvHeader='Date de début' csvFormat={dateFormatter} dataSort={true} width='180'>Début</TableHeaderColumn>
                <TableHeaderColumn dataField='end_date_hour' dataFormat={dateFormatter} csvHeader='Date de fin' csvFormat={dateFormatter} dataSort={true} width='180'>Fin</TableHeaderColumn>
                <TableHeaderColumn dataField='reference' dataSort={true} csvHeader='Référence de réservation' width='100'>Ref</TableHeaderColumn>
                <TableHeaderColumn dataField='bail_amount' dataSort={true} csvHeader='Caution' width='120' csvFormat={priceFormatter} dataFormat={priceFormatter}>Caution</TableHeaderColumn>
                <TableHeaderColumn dataField='site_tiers' dataSort={true} csvHeader='site réservation' width='150'>Site<br /> réservation</TableHeaderColumn>
                <TableHeaderColumn dataField='bail' dataSort={true} dataFormat={BailPaymentTypeFormatter} csvFormat={BailPaymentTypeFormatter}csvHeader='payé par' width='150'>Payé via</TableHeaderColumn>
                <TableHeaderColumn dataField='bail' dataSort={true} dataFormat={BailRetentionTotalFormatter} csvFormat={BailRetentionTotalFormatter} csvHeader='Montant à remboursé' width='150'>Montant<br /> à rembourser</TableHeaderColumn>
                <TableHeaderColumn dataField='bail' dataSort={true} dataFormat={BailRefundAtFormatter} csvFormat={BailRefundAtFormatter} csvHeader='Rembourssé le' width='220'>Remboursé le</TableHeaderColumn>
                <TableHeaderColumn dataField='bail' dataSort={true} dataFormat={BailRefundFromFormatter} csvFormat={BailRefundFromFormatter} csvHeader='Rembourssé par' width='220'>Remboursé par</TableHeaderColumn>
                <TableHeaderColumn dataField='bail' dataSort={true} dataFormat={BailAmountKeepFromFormatter} csvFormat={BailAmountKeepFromFormatter} csvHeader='Rembourssé par' width='220'>Montant conservé</TableHeaderColumn>
                <TableHeaderColumn dataField='bail' dataSort={true} csvHeader='retenues caution PC' width='220' csvFormat={(cell, row, formatExtraData, rowIdx) => bailFormatter(cell, row, formatExtraData, rowIdx, 'PC')} dataFormat={(cell, row, formatExtraData, rowIdx) => bailFormatter(cell, row, formatExtraData, rowIdx, 'PC')}>Retenues caution <br /> PC*</TableHeaderColumn>
                <TableHeaderColumn dataField='bail' dataSort={true} csvHeader='retenues caution RP' width='220' csvFormat={(cell, row, formatExtraData, rowIdx) => bailFormatter(cell, row, formatExtraData, rowIdx, 'RP')} dataFormat={(cell, row, formatExtraData, rowIdx) => bailFormatter(cell, row, formatExtraData, rowIdx, 'RP')}>Retenues caution <br /> RP*</TableHeaderColumn>
                <TableHeaderColumn dataField='bail' dataSort={true} csvHeader='retenues caution FP' width='220' csvFormat={(cell, row, formatExtraData, rowIdx) => bailFormatter(cell, row, formatExtraData, rowIdx, 'FP')} dataFormat={(cell, row, formatExtraData, rowIdx) => bailFormatter(cell, row, formatExtraData, rowIdx, 'FP')}>Retenues caution <br /> FP*</TableHeaderColumn>
                <TableHeaderColumn dataField='bail' dataSort={true} csvHeader='retenues caution CA' width='220' csvFormat={(cell, row, formatExtraData, rowIdx) => bailFormatter(cell, row, formatExtraData, rowIdx, 'CA')} dataFormat={(cell, row, formatExtraData, rowIdx) => bailFormatter(cell, row, formatExtraData, rowIdx, 'CA')}>Retenues caution <br /> CA*</TableHeaderColumn>
                <TableHeaderColumn dataField='bail' dataSort={true} csvHeader='retenues caution CS' width='220' csvFormat={(cell, row, formatExtraData, rowIdx) => bailFormatter(cell, row, formatExtraData, rowIdx, 'CS')} dataFormat={(cell, row, formatExtraData, rowIdx) => bailFormatter(cell, row, formatExtraData, rowIdx, 'CS')}>Retenues caution <br /> CS*</TableHeaderColumn>
            </BootstrapTable>
            <Row>
                <Col xs={12} md={6} className='text-center d-flex flex-column'>
                    <small>*PC : Prix coutant</small>
                    <small>*RP : Remboursement propriétaire</small>
                    <small>*FP : Facture prestataire</small>
                </Col>
                <Col xs={12} md={6} className='text-center d-flex flex-column'>
                    <small>*CA : Commission achat</small>
                    <small>*CS : Commission service</small>
                </Col>
            </Row>
        </>
    )
}