import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Badge, Image, Card, Modal } from 'react-bootstrap';
import InvoiceList from './invoice/invoiceList';
import '../bookingTab.scss';
import PaymentCard from './payment/paymentCard';
import OptionCard from './option/optionCard';
import { MdOutlineBedroomParent, MdOpenInNew } from 'react-icons/md';
import { BsPersonFill } from 'react-icons/bs';
import instanceOfAxios from '../../../../app/axios/axios';
import { FcCheckmark } from 'react-icons/fc';
import environnement from '../../../../app/environnement';


export default function BookingDetails(props) {
    const [listNames, setListNames] = useState([]);
    const [pending, setPending] = useState(true);
    const [visibility, setVisibility] = useState(false);
    const [booking, setBooking] = useState(props.booking);
    const files = { identité: 'Carte identité', facture: 'Facture', contrat : 'Contrat', rib: 'Rib', kbis: 'Kbis'};

    useEffect(() => {
        if (pending) {
            setPending(false)
            instanceOfAxios.get(`/booking/${props.booking.id}/getFilesName`)
                .then(res => setListNames(res.data.success))
        }
    }, [pending]);

    function handleChange(booking) {
        setBooking(booking);
        props.cardOnChange(booking);
    }

    function getDifferenceInDays(date1, date2) {
        const diffInMs = Math.abs(date2 - date1);
        return Math.round(diffInMs / (1000 * 60 * 60 * 24));
    }

    function returnBadgeState() {
        const stateColor = { ['trop perçu']: 'danger', 'annulé': 'danger', ['à payer']: 'danger', payé: 'success', ['acompte versé']: 'secondary', ['en attente']: 'dark' }

        let color = stateColor[booking.state];
        let state = booking.state;

        if (!booking.is_confirmed && booking.state !== 'annulé') {
            color = 'warning';
            state = 'attente validation';
        }
        return <Badge className="apartmentText verticalCenteredBadge" variant={color}>{state}</Badge>
    }
    function getOtherFile() {
        return listNames.length !== 0 ?
            listNames.map(file => {
                let createdAt = file ? new Date(file.created_at.replace(/\s/, 'T')) : null;
                var options = { year: 'numeric', month: 'numeric', day: 'numeric' };
                return (
                    <Col xs={12} md={6} className='w-100 d-flex justify-content-center noPadding mb-3'>
                        <div className='shadowForCard w-100'>
                            <Card.Header className='d-flex flex-column flex-md-row justify-content-around align-items-center'>
                                <a className='mr-4' target='_blank' href={`${environnement.axios}/admin/booking/${props.booking.id}/file/${file.name}`}>{files[file.name.toLowerCase()]} <MdOpenInNew/></a>
                                <p className='mr-4' style={{ margin: 'unset' }}>ajouté le : {createdAt.toLocaleDateString('fr-CA', options)}</p>
                            </Card.Header>      
                        </div>
                    </Col>
                )
            })
        :
            <p>Aucuns documents</p>
    }

    const beginDate = new Date(booking.begin_date_hour.replace(/\s/, 'T'));
    const endDate = new Date(booking.end_date_hour.replace(/\s/, 'T'));
    return (
        <Col>
            <Row className="justify-content-center">
                <Button className='goldenHover' onClick={() => setVisibility(true)} size="sm" variant="primary">
                    Détails
                </Button>
            </Row>
            <Modal
                backdropClassName="backdrop"
                style={{ zIndex: "20001" }}
                size='xl'
                show={visibility}
                onHide={() => setVisibility(false)}
                centered>
                <Modal.Header closeButton>
                    <Row className='noPadding d-flex justify-content-around align-items-center' style={{ width: '100%' }}>
                        <Col md={12} lg={4} className='reducePadding'>
                            <Modal.Title>
                                <p>Réservation : {booking.reference}</p>
                            </Modal.Title>
                        </Col>

                        {booking.site_tiers &&
                            <div className='d-flex justify-content-around align-items-center' style={{ margin: '.5vh' }}>
                                <Badge className="apartmentText" variant={'info'}>{booking.site_tiers}</Badge>
                            </div>}
                        <div className='d-flex justify-content-around align-items-center' style={{ margin: '.5vh' }}>
                            {returnBadgeState()}
                        </div>
                        <div className='d-flex justify-content-around align-items-center' style={{ margin: '.5vh' }}>
                            {booking.bail.is_payed ?
                                <Badge variant='success'>Caution payé</Badge>
                                :
                                <Badge variant='danger'>Caution à payer</Badge>
                            }
                        </div>
                        <div className='d-flex justify-content-around align-items-center' style={{ margin: '.5vh' }}>
                            {booking.amount_allready_payed > 0 &&
                                <InvoiceList invoices={booking.invoice_urls} bookingId={booking.id}></InvoiceList>}
                        </div>
                    </Row>
                </Modal.Header>
                <Modal.Body>
                    <Row className='d-flex justify-content-around' style={{ marginBottom: '2vh' }}>
                        <Col xs={12} sm={12} className="d-flex justify-content-center" style={{ marginBottom: '2vh' }}>
                            <a target='_blank' href={'/apartment/' + booking.apartment.id}>
                                <Button className='goldenHover'>Détail de l'annonce <MdOpenInNew /></Button>
                            </a>
                        </Col>
                        <Col md={9} xl={5} className="d-flex align-items-center justify-content-center">
                            <Image loading='lazy' src={booking.apartment.principal_picture_url.medium} fluid />
                        </Col>
                        <Col md={12} xl={7} style={{ marginBottom: '1vh', paddingLeft: 'unset', paddingRight: 'unset' }} className="d-flex align-items-center">
                            <Row>
                                <Col xs={12} className='mt-2'>
                                    <h2>Réf : <strong>{booking.apartment.reference}</strong></h2>
                                </Col>
                                <Col xs={12} sm={6}>
                                    <Col xs={12} className="d-flex justify-content-center" >
                                        <BsPersonFill style={{ height: '25px', width: '25px' }} />
                                    </Col>
                                    <Col xs={12} className="d-flex justify-content-center" style={{ paddingLeft: '2px', paddingRight: '2px' }}>
                                        <p className='text-center'>
                                            {booking.nb_person} {booking.nb_person > 1 ? 'Personnes' : 'Personne'}
                                        </p>
                                    </Col>
                                </Col>
                                <Col xs={12} sm={6}>
                                    <Col xs={12} className="d-flex justify-content-center">
                                        <MdOutlineBedroomParent style={{ height: '25px', width: '25px' }} />
                                    </Col>
                                    <Col xs={12} className="d-flex justify-content-center" style={{ paddingLeft: '2px', paddingRight: '2px' }}>
                                        <p className='text-center'>
                                            {booking.apartment.nb_room} {booking.apartment.nb_room > 1 ? 'Chambres' : 'Chambre'}
                                        </p>
                                    </Col>
                                </Col>
                                <Col xs={12}>
                                    <Card>
                                        <Card.Header>
                                            <Row>
                                                <Col xs={12} md={6} lg={4} className="d-flex justify-content-center noPadding">
                                                    <p className="apartmentText">Tarif : <strong>{(booking.rate).toFixed(2)} {booking.apartment.address.country === "SUISSE" || booking.apartment.address.country === "Suisse" ? 'CHF' : "€" }</strong></p>
                                                </Col>
                                                <Col xs={12} md={6} lg={4} className="d-flex justify-content-center noPadding">
                                                    <p className="apartmentText">Caution : <strong>{(booking.bail.amount).toFixed(2)} {booking.apartment.address.country === "SUISSE" || booking.apartment.address.country === "Suisse" ? 'CHF' : "€" }</strong></p>
                                                </Col>
                                                <Col xs={12} md={6} lg={4} className="d-flex justify-content-center noPadding">
                                                    <p className="apartmentText">Début : {beginDate.toLocaleDateString()}</p>
                                                </Col>
                                                <Col xs={12} md={6} lg={4} className="d-flex justify-content-center noPadding">
                                                    <p className="apartmentText">Payé : <strong>{(booking.amount_allready_payed).toFixed(2)} {booking.apartment.address.country === "SUISSE" || booking.apartment.address.country === "Suisse" ? 'CHF' : "€" }</strong></p>
                                                </Col>
                                                <Col xs={12} md={6} lg={4} className="d-flex justify-content-center noPadding">
                                                    <p className="apartmentText">Nuitées : {
                                                        getDifferenceInDays(beginDate, endDate)}</p>
                                                </Col>
                                                <Col xs={12} md={6} lg={4} className="d-flex justify-content-center noPadding">
                                                    <p className="apartmentText">Fin : {endDate.toLocaleDateString()}</p>
                                                </Col>
                                            </Row>
                                        </Card.Header>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row noGutters>
                        <Col md={12} lg={6}>
                            <Row noGutters >
                                <Col xs={11} >
                                    Options :
                                </Col>
                            </Row>
                            <div className={booking.options.length > 0 && "paymentScroll"}>
                                {booking.options.map((option) => {
                                    return (<OptionCard key={option.id + option.value} booking={booking} option={option} BookingDetailsOnChange={handleChange}></OptionCard>)
                                })}
                            </div>
                        </Col>
                        <Col md={12} lg={6}>
                            <Row noGutters >
                                <Col xs={11} >
                                    Paiements :
                                </Col>
                            </Row>
                            <div className="paymentScroll">
                                {booking.payments.map((payment) => {
                                    return (<PaymentCard key={payment.id + payment.state} booking={booking} payment={payment} BookingDetailsOnChange={handleChange} for='booking' payable={booking}></PaymentCard>)
                                })}
                                {booking.bail.payments.map((payment) => {
                                    return (<PaymentCard key={payment.id + payment.state} booking={booking} payment={payment} BookingDetailsOnChange={handleChange} for='bookingbail' payable={booking.bail}></PaymentCard>)
                                })}
                            </div>
                        </Col>
                    </Row>
                    <Row className='d-flex flex-column justify-content-center align-items-center'>
                        <Col sm={12} lg={12} className='d-flex justify-content-center justify-content-lg-end mt-3 mb-3'>
                            <div className='separator'
                                style={{
                                    border: '1px solid #B7A57E',
                                    color: '#b7a57e',
                                    width: '90%'
                                }}></div>
                        </Col>
                        <Col sm={12} lg={12} className='d-flex justify-content-center align-items-center mt-3 mb-3'>
                                <h3>Documents Supplémentaires</h3>
                        </Col>
                        {
                            getOtherFile()
                        }
                    </Row>
                </Modal.Body>
            </Modal>
        </Col>
    )
}
