import React, { useState } from 'react';
import { Card, Col, Row, Badge } from 'react-bootstrap'
import '../bookingTab.scss'
import BookingDetails from '../bookingDetails/bookingDetails';
import { FcHighPriority } from 'react-icons/fc';

export default function BookingCard(props) {

    const [booking, setBooking] = useState(props.booking);

    function handleChange(booking) {
        setBooking(booking);
    }

    function returnBadgeState() {
        const stateColor = { ['no Show']: 'secondary', ['trop perçu']: 'danger', 'annulé': 'danger', ['à payer']: 'danger', payé: 'success', ['acompte versé']: 'secondary', ['en attente']: 'dark' }

        let color = stateColor[booking.state];
        let state = booking.state;

        if (!booking.is_confirmed && booking.state !== 'annulé') {
            color = 'warning';
            state = 'attente validation';
        }
        return <Badge style={{ fontSize: "0.9rem" }} variant={color}>{state}</Badge>
    }

    const beginDate = new Date(booking.begin_date_hour.replace(/\s/, 'T'));
    const endDate = new Date(booking.end_date_hour.replace(/\s/, 'T'));
    const created = new Date(booking.created_at);
    let rerent = booking.re_rent_at ? new Date(booking.re_rent_at) : null;

    return (
        <Card className="apartmentReservationCard shadowForCard">
            <Card.Header className="apartmentReservationCardPart">
                <Row style={{ width: '100%' }}>
                    <Col className="d-flex align-items-center" sm={12} lg={4}>
                        <p className="apartmentRef">Locataire : {booking.contact && booking.contact.first_name + ' ' + booking.contact.last_name}</p>
                    </Col>
                    <Col className="d-flex align-items-center" sm={12} lg={2}>
                        {booking.site_tiers &&
                            <Badge style={{ fontSize: "0.9rem" }} variant={'info'}>{booking.site_tiers}</Badge>
                        }
                    </Col>
                    {/*si il s'est enregistré*/}
                    <Col className="d-flex align-items-center" sm={12} lg={2}>
                        {booking.personal_information?.is_complet ? (
                            <Badge style={{ fontSize: "0.9rem" }} variant="info">Enregistré</Badge>
                        ) : null}
                    </Col>
                    <Col className="d-flex align-items-center justify-content-center" sm={12} lg={3}>
                        {returnBadgeState()}
                    </Col>
                    <Col className="d-flex align-items-center justify-content-end" sm={12} lg={1}>
                        <BookingDetails booking={booking} cardOnChange={handleChange}/>
                    </Col>
                </Row>
            </Card.Header>
            <Card.Body className="apartmentReservationCardPart">
                <Row className='d-flex justify-content-center'>
                    <Col xs={12} md={6} lg={6} className='noPadding'>
                        <p className="apartmentText">Référence logement: <strong>{booking.apartment && booking.apartment.reference}</strong></p>
                        <p className="apartmentText">Référence réservation: <strong>{booking.reference}</strong></p>
                        <p className="apartmentText">Réservé le : <strong>{created.toLocaleDateString('fr-FR', { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' })}</strong></p>
                        {
                            booking.extend_for &&
                                <p className="apartmentText"> <FcHighPriority/> Extention de la réservation n° <strong>{booking.extend_for.reference}</strong></p>
                        }
                    </Col>
                    <Col xs={12} md={3} lg={3} className='noPadding'>
                        <p className="apartmentText">Du : {beginDate.toLocaleDateString()}</p>
                        <p className="apartmentText">Au : {endDate.toLocaleDateString()}</p>
                        {
                            rerent && <p className="apartmentText">Ré-ouverture : {rerent.toLocaleDateString()}</p>
                        }
                    </Col>
                    <Col xs={12} md={6} lg={3}>
                        <Row noGutters>
                            <Col xs={12} md={6}><p className="apartmentText d-flex justify-content-xl-end justify-content-center">Tarif : </p></Col>
                            <Col className="d-flex justify-content-xl-end justify-content-center" xs={12} md={6}><strong>{parseFloat(booking.rate).toFixed(2)} {booking.apartment.address.country === "SUISSE" || booking.apartment.address.country === "Suisse" ? 'CHF' : "€" }</strong></Col>
                            <Col xs={12} md={6}><p className="apartmentText d-flex justify-content-xl-end justify-content-center">Payé : </p></Col>
                            <Col className="d-flex justify-content-xl-end justify-content-center" xs={12} md={6}><strong>{parseFloat(booking.amount_allready_payed).toFixed(2)} {booking.apartment.address.country === "SUISSE" || booking.apartment.address.country === "Suisse" ? 'CHF' : "€" }</strong></Col>
                        </Row>
                    </Col>
                    {
                        props.tab && props.tab == 'bail' &&
                        <Col xs={12}>
                            <p className="d-flex justify-content-xl-end justify-content-center" > 
                                {booking.bail && booking.bail.refund_at ?
                                    <Badge style={{ fontSize: "0.9rem" }} variant='success'>Caution traitée</Badge>
                                    :
                                    <Badge style={{ fontSize: "0.9rem" }} variant='danger'>Caution à traiter</Badge>
                                }
                            </p>
                        </Col>
                    }
                    <Col xs={12}>
                        <p className="d-flex justify-content-xl-end justify-content-center" > 
                            {booking.bail && booking.bail.is_payed ?
                                <Badge style={{ fontSize: "0.9rem" }} variant='success'>Caution payée</Badge>
                                :
                                <Badge style={{ fontSize: "0.9rem" }} variant='danger'>Caution à payer</Badge>
                            }
                        </p>
                    </Col>
                    
                </Row>
            </Card.Body>
        </Card>
    )
}