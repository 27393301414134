import React from 'react';
import { Container, Col, Row, Image } from 'react-bootstrap';
import './statics.scss';

export default function TorgonSummer() {
    return (
        <Container
            style={{ paddingTop: '5vh', paddingBottom: '50px', maxWidth: '80vw', height: 'unset' }}
            className="noPadding"
        >
            <Row>
                <Col sm={12}>
                    <h2 style={{ textDecoration: 'underline 1px', textAlign: 'center', color: '#B7A57E' }}>
                        Torgon l’été
                    </h2>
                    <p className="text-justify text-center mt-4">
                        Au cœur des Alpes vaudoises, Torgon est une destination estivale idéale, alliant nature, sport et détente. Ce
                        village pittoresque, niché entre montagnes et lac, offre des panoramas à couper le souffle et une multitude
                        d'activités pour tous les âges. Que vous soyez passionné de randonnée, de trail, de vélo ou en quête de
                        moments ludiques en famille, Torgon saura vous combler. Partez à la découverte de la région à travers des
                        activités sportives et culturelles uniques, tout en profitant d’un cadre naturel exceptionnel.
                    </p>
                </Col>
            </Row>

            <hr className="page-separator" />

            <Row className="mt-4 bg-light">
                <Col sm={12} md={5}>
                    <Row>
                        <Col md={12} className="pb-2">
                            <Image
                                loading="lazy"
                                src="/images/image_page/torgon_ete/verbier_bikepark3.jpg"
                                alt="Sports de Glisse"
                                fluid
                                className="rounded"
                                style={{
                                    width: '100%',
                                    height: '200px', // Hauteur fixe
                                    objectFit: 'cover', // Maintenir un recadrage propre
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <Image
                                loading="lazy"
                                src="/images/image_page/torgon_ete/rando.jpg"
                                alt="Sports de Glisse"
                                fluid
                                className="rounded"
                                style={{
                                    width: '100%',
                                    height: '130px', // Hauteur fixe
                                    objectFit: 'cover', // Maintenir un recadrage propre
                                }}
                            />
                        </Col>

                        <Col md={6}>
                            <Image
                                loading="lazy"
                                src="/images/image_page/torgon_ete/rando_2.jpg"
                                alt="Sports de Glisse"
                                fluid
                                className="rounded"
                                style={{
                                    width: '100%',
                                    height: '130px', // Hauteur fixe
                                    objectFit: 'cover', // Maintenir un recadrage propre
                                }}
                            />
                        </Col>
                    </Row>
                </Col>
                <Col sm={12} md={7} className="p-3">
                    <h3>Sports de plein air</h3>
                    <p className="text-justify">
                        Venez profiter des <strong>grands espaces de Torgon</strong> et laissez-
                        vous tenter par l’aventure en pleine nature ! Que vous soyez
                        amateur de <strong>randonnée pédestre</strong>, passionné de <strong>trail running</strong>
                         ou adepte de <strong>vélo</strong> et <strong>E-bike</strong>, la station vous offre des sentiers
                        balisés aves une vue spectaculaires et <strong>8 parcours</strong> totalisant
                                <strong> 130 km</strong>, adaptés à tous les niveaux. Une immersion grandeur
                        nature vous attend, entre forêts, sommets et vues imprenables
                        sur le Lac Léman.
                    </p>
                </Col>
            </Row>

            <Row className="mt-4 bg-light">
                <Col sm={12} md={7} className="p-3">
                    <h3>Sports de raquette</h3>
                    <p className="text-justify">
                        Venez passer un moment convivial à Torgon <strong>en famille</strong> ou <strong>en
                        couple</strong>  ! Profitez des installations du <strong>Tennis-Club</strong>  pour une
                        partie de <strong>tennis</strong>  ou de <strong>padel</strong> , ou essayez le <strong>badminton</strong>  et le
                                <strong> mini-tennis</strong>  pour les plus jeunes. Tout cela avec une vue
                        imprenable sur les <strong>magnifiques paysages alpins</strong>  ! Que ce soit
                        pour un match amical ou un défi en famille, les sports de
                        raquette à Torgon sont l'occasion idéale de partager des
                        moments actifs et amusants ensemble !
                    </p>
                </Col>
                <Col sm={12} md={5}>
                    <Image
                        loading="lazy"
                        src="/images/image_page/torgon_ete/raquette.jpg"
                        alt="Activités en Pleine Nature"
                        fluid
                        className="rounded"
                        style={{
                            width: '100%',
                            height: '250px', // Hauteur fixe
                            objectFit: 'cover', // Maintenir un recadrage propre
                        }}
                    />
                </Col>
            </Row>

            <Row className="mt-4 bg-light">
                <Col sm={12} md={5}>
                    <Row>
                        <Col md={6} className="pb-2">
                            <Image
                                loading="lazy"
                                src="/images/image_page/torgon_ete/pumptrack-torgon.jpg"
                                alt="Sports de Glisse"
                                fluid
                                className="rounded"
                                style={{
                                    width: '100%',
                                    height: '130px', // Hauteur fixe
                                    objectFit: 'cover', // Maintenir un recadrage propre
                                }}
                            />
                        </Col>

                        <Col md={6}>
                            <Image
                                loading="lazy"
                                src="/images/image_page/torgon_ete/jeu.jpg"
                                alt="Sports de Glisse"
                                fluid
                                className="rounded"
                                style={{
                                    width: '100%',
                                    height: '130px', // Hauteur fixe
                                    objectFit: 'cover', // Maintenir un recadrage propre
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <Image
                                loading="lazy"
                                src="/images/image_page/torgon_ete/tir_a_larc.jpg"
                                alt="Sports de Glisse"
                                fluid
                                className="rounded"
                                style={{
                                    width: '100%',
                                    height: '200px', // Hauteur fixe
                                    objectFit: 'cover', // Maintenir un recadrage propre
                                }}
                            />
                        </Col>
                    </Row>
                </Col>
                <Col sm={12} md={7} className="p-3">
                    <h3>Activités Ludiques et Familiale</h3>
                    <p className="text-justify">
                        Testez vos talents au tir à l'arc sur le plus grand parcours du
                        Valais avec ses <strong>19 cibles en pleine nature</strong> !
                        En famille, direction <strong>Torgonland</strong> : <strong>mini-golf</strong>, <strong> tyrolienne</strong>,
                        <strong> château gonflable</strong>, <strong> ping-pong</strong>, <strong> pétanque</strong>, <strong> pumptrack</strong>, et
                            bien plus encore.
                        Pour les passionnés de nature, découvrez l'initiation à la
                            <strong> photographie animalière</strong> et capturez la faune locale dans
                        son habitat naturel.
                    </p>
                </Col>
            </Row>

            <Row className="mt-4 bg-light">
                <Col xs={12} md={7} className="p-3">
                    <h3>Activités Aquatiques</h3>
                        <p className="text-justify">
                            Venez vivre une journée inoubliable à <strong>Aquaparc</strong> au Bouveret,
                            à seulement <strong>30 minutes de Torgon</strong> ! Profitez de <strong> toboggans</strong>,
                            <strong>piscines à vagues</strong>, aires de <strong>jeux aquatiques</strong> et bien plus, au
                            bord du <strong>Lac Léman</strong>. Que vous cherchiez des sensations fortes
                            ou de la détente, Aquaparc propose des attractions pour
                                    <strong> toute la famille</strong>. Un lieu parfait pour s'amuser et se rafraîchir
                            en été !
                        </p>
                </Col>
                <Col sm={12} md={5}>
                    <Image
                        loading="lazy"
                        src="/images/image_page/torgon_ete/aqua.jpg"
                        alt="parapente-les-menuires"
                        fluid
                        className="rounded"
                        style={{
                            width: '100%',
                            height: '250px', // Hauteur fixe
                            objectFit: 'cover', // Maintenir un recadrage propre
                        }}
                    />
                </Col>
            </Row>

            <Row className="mt-4 align-items-center bg-light">
                <Col xs={12} md={5}>
                    <Row>
                        <Col md={12} className="pb-2">
                            <Image
                                loading="lazy"
                                src="/images/image_page/torgon_ete/charette.jpg"
                                alt="Sports de Glisse"
                                fluid
                                className="rounded"
                                style={{
                                    width: '100%',
                                    height: '200px', // Hauteur fixe
                                    objectFit: 'cover', // Maintenir un recadrage propre
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <Image
                                loading="lazy"
                                src="/images/image_page/torgon_ete/image.jpg"
                                alt="Sports de Glisse"
                                fluid
                                className="rounded"
                                style={{
                                    width: '100%',
                                    height: '130px', // Hauteur fixe
                                    objectFit: 'cover', // Maintenir un recadrage propre
                                }}
                            />
                        </Col>
                        <Col md={6}>
                            <Image
                                loading="lazy"
                                src="/images/image_page/torgon_ete/western-scaled.jpg"
                                alt="Sports de Glisse"
                                fluid
                                className="rounded"
                                style={{
                                    width: '100%',
                                    height: '130px', // Hauteur fixe
                                    objectFit: 'cover', // Maintenir un recadrage propre
                                }}
                            />
                        </Col>
                    </Row>
                </Col>
                <Col xs={12} md={7} className="p-3">
                    <h3>Aventure et Mystères</h3>
                    <p className="text-justify">
                        À Torgon, l’aventure vous attend avec deux <strong>Escape games </strong>
                        excitants. Testez votre esprit de déduction et plongez dans des
                        <strong> énigmes captivantes</strong>, parfaites pour les enfants et à partager
                        en famille ou entre amis.
                        Profitez aussi de <strong>Western Park</strong>, où l’univers du <strong> Far West</strong>
                                devient un terrain de jeu. Résolvez des défis et vivez une
                                <strong> expérience immersive</strong> qui ravira petits et grands.
                        Une aventure palpitante à ne pas manquer en famille !
                    </p>
                </Col>
            </Row>

            <Row className="mt-4 align-items-center bg-light">
                <Col xs={12} md={7} className="p-3">
                    <h3>Activités Culturelles</h3>
                    <p className="text-justify">
                        <strong>Le Château d'Aigle</strong> , à 30 minutes de Torgon, est un <strong>site
                        médiéval</strong> abritant le <strong>Musée de la Vigne et du Vin</strong>. Explorez
                        son histoire fascinante et son architecture imposante. À ne pas
                        manquer, le <strong>Fort de Chillon</strong>, sur les rives du <strong>Lac Léman</strong>, un
                        site historique avec des <strong>vues spectaculaires</strong> et des
                        fortifications impressionnantes. Une immersion dans le
                        patrimoine de la région, entre histoire et paysages magnifiques
                        !
                    </p>
                </Col>
                <Col xs={12} md={5}>
                    <Image
                        loading="lazy"
                        src="/images/image_page/torgon_ete/chateau.jpg"
                        alt="Pont suspendu à Torgon"
                        fluid
                        className="rounded"
                        style={{
                            width: '100%',
                            height: '250px', // Hauteur fixe
                            objectFit: 'cover', // Maintenir un recadrage propre
                        }}
                    />
                </Col>
            </Row>

            <Row className="mt-4 align-items-center bg-light">
                <Col xs={12} md={5}>
                    <Image
                        loading="lazy"
                        src="/images/image_page/torgon_ete/parapente.jpg"
                        alt="Pont suspendu à Torgon"
                        fluid
                        className="rounded"
                        style={{
                            width: '100%',
                            height: '250px', // Hauteur fixe
                        }}
                    />
                </Col>
                <Col xs={12} md={7} className="p-3">
                    <h3>Sports Extremes</h3>
                    <p className="text-justify">
                        Cet été, laissez-vous emporter par l'adrénaline en survolant
                        Torgon en <strong>parapente</strong> ! Offrant des vues imprenables sur les
                        <strong> Alpes Suisses</strong> et le <strong>Lac Léman</strong>, ce vol en plein air est l’occasion
                        rêvée de découvrir la région sous un angle unique. Que vous
                        soyez un <strong>pilote expérimenté</strong> ou un <strong>débutant</strong>, les conditions
                        idéales de Torgon vous garantissent une expérience exaltante
                        et inoubliable.
                    </p>
                </Col>
            </Row>
        </Container>
    );
}
