import React, { useEffect } from 'react';
import { Navbar, Nav, Col, Row } from 'react-bootstrap'
import { connect } from 'react-redux';
import { FaHeart } from 'react-icons/fa'
import {
  getAccount,
  getRole,
  getIsConnected
} from './headerSlice';
// include styles
import './header.scss';
import MyAccountButton from './myAccount/myAccountButton';
import CookiesConsent from '../cookies/CookiesConsent';

const Header = ({ isConnected, account, role }) => {

  function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
    return null; // Retourne null si le cookie n'existe pas
  }

  const href = () => {

    let datas = [];

    if(getCookie('country') === "suisse"){
      datas = [
        { label: 'ACCUEIL', href: '/' },
        { label: 'LOGEMENTS', href: '/Logements' },
        { label: 'TORGON L\'HIVER', href: '/Torgon-hiver' },
        { label: 'TORGON L\'ETE', href: '/Torgon-ete' },
        { label: 'LOCATAIRES', href: '/Locataires' },
        { label: 'PROPRIÉTAIRES', href: '/Proprietaires', col: 2 },
        { label: 'CONTACT', href: '/Contact' },
        { label: 'Magazine', href: '/Magazine' }
      ];
    }else{
      datas = [
        { label: 'ACCUEIL', href: '/' },
        { label: 'LOGEMENTS', href: '/Logements' },
        { label: 'LOCATAIRES', href: '/Locataires' },
        { label: 'PROPRIÉTAIRES', href: '/Proprietaires', col: 2 },
        { label: 'L\'AGENCE', href: '/Agence' },
        { label: 'CONTACT', href: '/Contact' },
        { label: 'Magazine', href: '/Magazine' }
      ];
    }

    return datas.map(balise => balise.label != 'Magazine' ?
      <Col lg={balise.col ? balise.col : ''} md={window.innerWidth < 990 ? 12 : ''} className='reducePadding'>
        <a className={window.location.pathname == balise.href ? "navItems allreadyUnderlined" : "navItems"} href={balise.href}>{balise.label}</a>
      </Col>
      :
      <Col className='reducePadding' lg={1} md={12}>
        <a className={window.location.pathname == balise.href ? "navItems allreadyUnderlined magazine" : "navItems magazine"} href={balise.href}>{balise.label}</a>
      </Col>
    )
  }

  useEffect(() => {
    account();
  });

  return (
    <header className="header-sticky">
      <Navbar collapseOnSelect expand='lg' bg="white" variant="light" className="d-flex justify-content-center">
        <Navbar.Toggle aria-controls="responsive-navbar-nav">
          <svg xmlns="http://www.w3.org/2000/svg" width="47.517" height="27.718" viewBox="0 0 47.517 27.718">
            <g id="Menuburger" transform="translate(-4.5 -10.125)">
              <path id="Tracé_7" data-name="Tracé 7" d="M50.037,14.085H6.48A1.986,1.986,0,0,1,4.5,12.1h0a1.986,1.986,0,0,1,1.98-1.98H50.037a1.986,1.986,0,0,1,1.98,1.98h0A1.986,1.986,0,0,1,50.037,14.085Z" transform="translate(0 0)" fill="#b7a57e" />
              <path id="Tracé_8" data-name="Tracé 8" d="M50.037,20.835H6.48a1.986,1.986,0,0,1-1.98-1.98h0a1.986,1.986,0,0,1,1.98-1.98H50.037a1.986,1.986,0,0,1,1.98,1.98h0A1.986,1.986,0,0,1,50.037,20.835Z" transform="translate(0 5.129)" fill="#b7a57e" />
              <path id="Tracé_9" data-name="Tracé 9" d="M50.037,27.585H6.48A1.986,1.986,0,0,1,4.5,25.6h0a1.986,1.986,0,0,1,1.98-1.98H50.037a1.986,1.986,0,0,1,1.98,1.98h0A1.986,1.986,0,0,1,50.037,27.585Z" transform="translate(0 10.259)" fill="#b7a57e" />
            </g>
          </svg>
          <Col lg={4} md={12} className='noPadding'>
            <img src="/logoHeader/Logo-header.webp" alt="logo-header" className="logo-Navbar-Responsive" loading="lazy"/>
          </Col>
        </Navbar.Toggle>

        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="d-flex align-items-center justify-content-between" style={{ padding: '1vh' }}>
            <a href='/'>
              <img className="logo-Navbar" src="/logoHeader/Logo-header.webp" alt="logo-header" loading="lazy"/>
            </a>
            <Row className='noPadding d-flex align-items-center justify-content-end nav-Bar-linkWidth'>
              {
                href()
              }
              <Col lg={2} md={12} className="d-flex justify-content-lg-around justify-content-center align-items-center">
                <a href="/Favoris">
                  <svg className='navItems-favoris' xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
                    <g id="favoris" transform="translate(-59.371 -927)">
                      <g id="Ellipse_6" data-name="Ellipse 6" transform="translate(59.371 927)" fill="#fff" stroke="#fff" stroke-width="2">
                        <circle cx="15" cy="15" r="15" stroke="none" />
                        <circle cx="15" cy="15" r="14" fill="none" />
                      </g>
                      <path id="Icon_feather-heart" data-name="Icon feather-heart" d="M18.054,5.812a4.485,4.485,0,0,0-6.344,0l-.864.864-.864-.864a4.486,4.486,0,1,0-6.344,6.344l.864.864,6.344,6.344L17.19,13.02l.864-.864a4.485,4.485,0,0,0,0-6.344Z" transform="translate(63.396 930.165)" fill='none' stroke="#b7a57e" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                    </g>
                  </svg>
                </a>
                <MyAccountButton role={role} isConnected={isConnected} />
              </Col>
            </Row>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </header>
  );
}

const mapStateToProps = state => {
  return {
    isConnected: getIsConnected(state),
    role: getRole(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    account: () => dispatch(getAccount())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);