import { useEffect, useState } from 'react';
import { Modal, Button, Row, Col, Table, Form, Dropdown, FormCheck } from 'react-bootstrap';
import { connect, useDispatch, useStore } from 'react-redux';
import instanceOfAxios from '../../app/axios/axios';
import { setIsConnected } from '../header/headerSlice';
import Spinner from '../spinner/Spinner';
import { show } from '../admin/Alerter/alerterSlice';
import { FcHighPriority } from 'react-icons/fc';
import DateRange from '../searchBar/dateRange/dateRange';
import { getParam } from '../searchBar/searchBarSlice';
import SkeletonContent from './skeletonContent/skeletonContent';
import { getBeginDate, getEndDate } from '../searchBar/dateRange/dateRangeSlice';
import environnement from '../../app/environnement';

function ApartmentModal(props) {
    const [pending, setPending] = useState(true);
    const [pendingRegister, setPendingRegister] = useState(false);
    const [pendingLogin, setPendingLogin] = useState(false);
    const [indispo, setIndispo] = useState(false);
    const [visibility, setVisibility] = useState(false);
    const [booking, setBooking] = useState();
    const [bailBooking, setBailBooking] = useState();
    const [bookingError, setBookingError] = useState([]);
    const [visibleForm, setVisibleForm] = useState();
    const [isConnected, setThisIsConnected] = useState(useStore().getState().header.isConnected);
    const [registerParam, setRegisterParam] = useState({});
    const [registerError, setRegisterError] = useState({});
    const [passwordVisibility, setPasswordVisibility] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);
    const [isSociety, setIsSociety] = useState(false);
    const [dataForm, setDataForm] = useState([]);
    const [user, setUser] = useState();
    const [pendingUser, setPendingUser] = useState(true);
    const [cglAccepted, setCglAccepted] = useState(false);
    const [askConfirmClose, setAskConfirmClose] = useState(false);
    const [askConfirmVisibility, setAskConfirmVisibility] = useState(false);
    const dispatch = useDispatch();
    const optionsDates = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric' };

    useEffect(() => {
        setPending(true);
    }, props.param);

    if (pending && visibility) {
        setIndispo(false);
        setIsSubmit(true);
        let query = {};
        for (const [key, value] of Object.entries(props.param)) {
            query[key] = value;
        }
        if (props.beginDate) {
            query.begin_date_hour = props.beginDate
        }
        if (props.endDate) {
            query.end_date_hour = props.endDate
        }
        instanceOfAxios.post('/apartment/' + props.apartment.id + '/recalculateBooking', query)
            .then((res) => {
                setBooking(res.data.succes);
                setIsSubmit(false);
            })
            .catch(err => {
                setIsSubmit(false);
                setIndispo(true);
            }
            );
        setPending(false);
    }

    if (pendingUser) {
        instanceOfAxios.get('/account')
            .then((res) => {
                setUser(res.data);
            });
        setPendingUser(false);
    }

    function changeValue(event) {
        if (event.target && event.target.name == 'nb_person') {
            dispatch(changeValue({ type: 'nb_person', payload: event.target.value }))
        } else {
            dispatch(changeValue({ type: event.type, payload: event.payload }))
        }
    }

    function getDifferenceInDays(date1, date2) {
        const diffInMs = Math.abs(date2 - date1);
        return Math.round(diffInMs / (1000 * 60 * 60 * 24));
    }

    function handleSubmitBooking() {
        setIsSubmit(true);
        if (!booking.contact_id && user.principal_contact) {
            /*la on a bail_amount*/
            booking.contact_id = user.principal_contact.id;
            setBooking(booking);
        } else if (!booking.contact_id && !user.principal_contact) {
            dispatch(show({ message: 'Aucun contact n\'a pu être trouvé, rendez-vous dans l\'onglet Mon compte', variant: 'danger' }));
        }
        if (booking.contact_id) {
            instanceOfAxios.post('/apartment/' + props.apartment.id + '/booking', booking)
                .then((res) => {
                    dispatch(show({ message: 'Votre demande de réservation à bien été prise en compte, vous pouvez procéder au paiement', variant: 'success' }));
                    setBooking(res.data.succes);

                    if(booking.bail_amount){
                        setBailBooking(booking.bail_amount);
                    }

                    setAskConfirmClose(true);
                    instanceOfAxios.get('/booking/' + res.data.succes.id + '/payment/' + res.data.succes.payments[0].id + '/edit')
                        .then((res) => {
                            let datas = [];
                            for (const [key, value] of Object.entries(res.data.form)) {
                                datas.push(<input type="hidden" name={key} value={value}></input>);
                            }
                            setDataForm(datas);
                            setIsSubmit(false);
                        });
                })
                .catch((error) => {
                    let message = 'Une erreur est survenue lors de l\'enregistrement de votre réservation'
                    if (error.response.data.error === 'contact sans adresse') {
                        message = 'Vous ne pouvez pas créer de réservation pour ce contact car il ne possède pas d\'adresse'
                    }
                    dispatch(show({ message: message, variant: 'danger' }));
                    setIsSubmit(false);
                })
        } else {
            let error = [];
            error['contact_id'] = 'Veuillez selectionner un contact';
            setBookingError(error);
            setIsSubmit(false);
        }
    }

    function handleRegisterChange(event) {
        let param = Object.assign({}, registerParam);
        param[event.target.name] = event.target.value;
        setRegisterParam(param);
    }

    function handleLogin() {
        setPendingLogin(true);
        instanceOfAxios.post('/login', registerParam)
            .then((res) => {
                setPendingLogin(false);
                dispatch(show({ message: 'Vous êtes connecté', variant: 'success' }));
                setThisIsConnected(true);
                setUser(res.data.account);
                dispatch(setIsConnected(true));
                setVisibleForm('');
            })
            .catch((error) => {
                setPendingLogin(false);
                dispatch(show({ message: 'Erreur lors de la connexion', variant: 'danger' }));
            })
    }

    function handleRegister() {
        setPendingRegister(true);
        instanceOfAxios.post('/registerInRow', registerParam)
            .then((res) => {
                setPendingRegister(false);
                instanceOfAxios.post('/login', registerParam)
                    .then((res) => {
                        dispatch(show({ message: 'Création de compte réussi', variant: 'success' }));
                        setThisIsConnected(true);
                        setUser(res.data.account);
                        dispatch(setIsConnected(true));
                        setVisibleForm('');
                    })
            })
            .catch((error) => {
                setPendingRegister(false);
                dispatch(show({ message: 'Une erreur est survenue lors de la création de votre compte', variant: 'danger' }));
                handleError(error, 'register');
            })
    }

    function handleBankTransfertSubmit() {
        instanceOfAxios.get('/apartment/' + props.apartment.id + '/booking/' + booking.id + '/sendRibToTenant')
            .then((res) => {
                dispatch(show({ message: 'Un email contenant le RIB de l\'entreprise vien d\'être envoyé', variant: 'success' }));
            })
            .catch((error) => {
                dispatch(show({ message: 'Une erreur est survenue lors de l\'envoi du RIB', variant: 'danger' }));
            })
    }

    function handleError(error, type) {
        let arrayError = [];
        Object.keys(error.response.data).forEach(function (key) {
            arrayError[key] = error.response.data[key];
        });
        if (type === 'register') {
            setRegisterError(arrayError);
        }
    }

    function handleIsSocietyChange(event) {
        let param = Object.assign({}, registerParam);
        let bool = 0;
        if (event.target.checked) {
            bool = 1;
        }
        param.is_society = bool;
        setRegisterParam(param);
        setIsSociety(event.target.checked);
    }

    function getForm() {
        if (visibleForm === 'register') {
            return (
                <Form style={{ paddingBottom: '10px', paddingTop: '10px' }}>
                    <Row>
                        <Col sm={12} lg={12} className='d-flex justify-content-center justify-content-lg-end mt-3 mb-3'>
                            <div className='separator'
                                style={{
                                    border: '1px solid #B7A57E',
                                    color: '#b7a57e',
                                    width: '90%'
                                }}></div>
                        </Col>
                        <Col md={12}>
                            <p>Les champs marqués d'une * sont obligatoire</p>
                        </Col>
                        <Col md={12} lg={4}>
                            <Form.Group>
                                <Form.Label>Email *</Form.Label>
                                <Form.Control name='email' size="sm" placeholder="Exemple@exemple.com" onChange={handleRegisterChange} />
                                {registerError['email'] ? <small style={{ color: "red" }}>{registerError['email']}</small> : <></>}
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Mot de passe *</Form.Label>
                                <Form.Control type={!passwordVisibility ? 'password' : 'text'} name='password' size="sm" placeholder="********" onChange={handleRegisterChange} />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Confirmer le mot de passe *</Form.Label>
                                <Form.Control type={!passwordVisibility ? 'password' : 'text'} name='confirm_password' size="sm" placeholder="********" onChange={handleRegisterChange} />
                            </Form.Group>
                            <Form.Group controlId="formBasicCheckbox">
                                <Form.Check type="checkbox" onClick={(e) => setPasswordVisibility(e.target.checked)} label='Afficher le mot de passe' />
                            </Form.Group>
                            {
                                !registerError['password'] ?
                                    <p style={{ textAlign: 'center', color: 'red' }}>Le mot de passe doit être formé d'au minimum 8 caractères, contenir un numéro, une majuscule, une minuscule et un caractère spécial</p>
                                    :
                                    <></>
                            }
                            <ul>
                                {registerError['password'] ? registerError['password'].map(error => <li htmlFor="password"><small style={{ color: "red" }}>{error}</small></li>) : <></>}
                            </ul>
                        </Col>
                        <Col md={12} lg={4}>
                            <Form.Group id="civility">
                                <Form.Label>Civilité *</Form.Label>
                                <Form.Control name="civility" as="select" onChange={handleRegisterChange}>
                                    <option>Selectionner</option>
                                    <option value='Mme'>Mme</option>
                                    <option value='Mr'>Mr</option>
                                </Form.Control>
                                {registerError['civility'] ? <small style={{ color: "red" }}>{registerError['civility']}</small> : <></>}
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Nom *</Form.Label>
                                <Form.Control name='last_name' size="sm" placeholder="Dupont" onChange={handleRegisterChange} />
                                {registerError['last_name'] ? <small style={{ color: "red" }}>{registerError['last_name']}</small> : <></>}
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Prénom *</Form.Label>
                                <Form.Control name='first_name' size="sm" placeholder="Jean" onChange={handleRegisterChange} />
                                {registerError['first_name'] ? <small style={{ color: "red" }}>{registerError['first_name']}</small> : <></>}
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Téléphone *</Form.Label>
                                <Form.Control name='phone' size="sm" placeholder="0694543234" onChange={handleRegisterChange} />
                                {registerError['phone'] ? <small style={{ color: "red" }}>{registerError['phone']}</small> : <></>}
                            </Form.Group>
                        </Col>
                        <Col md={12} lg={4}>
                            <Form.Group>
                                <Form.Label>Adresse *</Form.Label>
                                <Form.Control name='address' size="sm" placeholder="450 rue des lilas" onChange={handleRegisterChange} />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Deuxième partie</Form.Label>
                                <Form.Control name='second_address' size="sm" placeholder="" onChange={handleRegisterChange} />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Numéro de logement</Form.Label>
                                <Form.Control name='third_address' size="sm" placeholder="001" onChange={handleRegisterChange} />
                            </Form.Group>
                            <Form.Row>
                                <Col lg={12} xl={6}>
                                    <Form.Group>
                                        <Form.Label>Ville *</Form.Label>
                                        <Form.Control name='city' size="sm" placeholder="Montpellier" onChange={handleRegisterChange} />
                                    </Form.Group>
                                </Col>
                                <Col lg={12} xl={6}>
                                    <Form.Group>
                                        <Form.Label>Code Postale *</Form.Label>
                                        <Form.Control name='postal_code' size="sm" placeholder="34000" onChange={handleRegisterChange} />
                                    </Form.Group>
                                </Col>
                            </Form.Row>
                        </Col>
                        <Col xs={12} className='d-flex justify-content-center'>
                            <Form.Group controlId="formBasicCheckbox">
                                <Form.Check type="checkbox" onClick={(e) => handleIsSocietyChange(e)} label='Ce compte est une société' />
                            </Form.Group>
                        </Col>
                        {isSociety ? <>
                            <Col md={12} lg={6}>
                                <Form.Group>
                                    <Form.Label>Nom de la société *</Form.Label>
                                    <Form.Control name='name' size="sm" onChange={handleRegisterChange} />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Numéro de SIRET *</Form.Label>
                                    <Form.Control name='SIRET' size="sm" placeholder="443xxxxxxxxxxx" onChange={handleRegisterChange} />
                                    {registerError['SIRET'] ? <small style={{ color: "red" }}>{registerError['SIRET']}</small> : <></>}
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Numero de num_TVA *</Form.Label>
                                    <Form.Control name='num_TVA' size="sm" placeholder="443xxxxxx " onChange={handleRegisterChange} />
                                    {registerError['num_TVA'] ? <small style={{ color: "red" }}>{registerError['num_TVA']}</small> : <></>}
                                </Form.Group>
                            </Col>
                            <Col md={12} lg={6}>
                                <Form.Group>
                                    <Form.Label>Adresse de la société *</Form.Label>
                                    <Form.Control name='society_address' size="sm" placeholder="450 rue des lilas" onChange={handleRegisterChange} />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Deuxième partie</Form.Label>
                                    <Form.Control name='society_second_address' size="sm" placeholder="" onChange={handleRegisterChange} />
                                </Form.Group>
                                <Form.Row>
                                    <Col xs={12} md={6}>
                                        <Form.Group>
                                            <Form.Label>Ville *</Form.Label>
                                            <Form.Control name='society_city' size="sm" placeholder="Montpellier" onChange={handleRegisterChange} />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <Form.Group>
                                            <Form.Label>Code Postale *</Form.Label>
                                            <Form.Control name='society_postal_code' size="sm" placeholder="34000" onChange={handleRegisterChange} />
                                        </Form.Group>
                                    </Col>
                                </Form.Row>
                            </Col>
                        </>
                            :
                            <></>}
                        <Col md={12} className='d-flex justify-content-center'>
                            {
                                pendingRegister ?
                                    <Spinner />
                                    :
                                    <Button variant='secondary' onClick={handleRegister}>Valider</Button>
                            }
                        </Col>
                    </Row>
                </Form>
            )
        } else if (visibleForm === 'connect') {
            return (
                <Form style={{paddingBottom: '10px', paddingTop: '10px', width: '100%' }} className='justify-content-center'>
                    <Row className='d-flex justify-content-center'>
                        <Col sm={12} lg={12} className='d-flex justify-content-center justify-content-lg-end mt-3 mb-3'>
                            <div className='separator'
                                style={{
                                    border: '1px solid #B7A57E',
                                    color: '#b7a57e',
                                    width: '90%'
                                }}></div>
                        </Col>
                        <Col xs={10} lg={7}>
                            <Form.Group>
                                <Form.Label>Email *</Form.Label>
                                <Form.Control name='email' size="sm" placeholder="Exemple@exemple.com" onChange={handleRegisterChange} />
                            </Form.Group>
                        </Col>
                        <Col xs={10} lg={7}>
                            <Form.Group>
                                <Form.Label>Mot de passe *</Form.Label>
                                <Form.Control type='password' name='password' size="sm" placeholder="********" onChange={handleRegisterChange} />
                            </Form.Group>
                        </Col>
                        <Col xs={6} className='d-flex justify-content-center'>
                            {
                                pendingLogin ?
                                    <Spinner />
                                    :
                                    <Button variant='secondary' onClick={handleLogin}>Valider</Button>
                            }
                        </Col>
                    </Row>
                </Form>
            )
        }
    }

    function handleNumberChange(event) {
        setIsSubmit(true);
        booking[event.target.name] = event.target.value || event.target.getAttribute('value');
        instanceOfAxios.post('/apartment/' + props.apartment.id + '/recalculateBooking', booking)
            .then((res) => {
                setIsSubmit(false);
                setBooking(res.data.succes);
            })
            .catch((error) => {
                dispatch(show({ message: 'Estimation impossible', variant: 'danger' }));
                setIsSubmit(false);
            })
    }

    function getMaxNumber(name, active = true) {
        let options = [];
        if (name !== undefined) {
            let loop = booking && booking['nb_person'] && name != 'nb_person' ? parseInt(booking['nb_person']) : props.apartment[name];
            options.push(<option key='0' name={name}>0</option>);
            for (let i = 1; i <= props.apartment[name]; i++) {
                let selected = booking && booking[name] == i && active ? true : false;
                options.push(<option key={name + i} name={name} selected={selected}>{i}</option>);
            }
        }
        return options;
    }

    function handleCglChecked(boolean) {
        setCglAccepted(boolean);
        booking.cgl_accepted = boolean;
        setBooking(booking);
    }

    function getButton() {
        if (!isConnected) {
            return (
                <>
                    <Row>
                        <Col xs={12} className='d-flex justify-content-center align-items-center'>
                            <p className='text-center'>Pour réserver veuillez vous enregistrer ou vous connecter</p>
                        </Col>
                        <Col lg={12} style={{ paddingLeft: '0', paddingRight: '0' }} className='d-flex justify-content-center align-items-center'>
                            <Button className='goldenHover' style={{ margin: "5px" }} onClick={() => {
                                setIsSociety(false);
                                setVisibleForm("connect")
                                if (visibleForm == 'connect') {
                                    setVisibleForm("")
                                }
                            }}>
                                Déjà Client ?
                            </Button>
                        </Col>
                        <Col lg={12} style={{ paddingLeft: '0', paddingRight: '0' }} className='d-flex justify-content-center align-items-center'>
                            <Button className='goldenHover' style={{ margin: "5px" }} onClick={() => {
                                setVisibleForm("register")
                                if (visibleForm == 'register') {
                                    setVisibleForm("")
                                }
                            }}>
                                Je créer mon compte
                            </Button>
                        </Col>
                    </Row>
                </>

            )
        } else if (isConnected && booking.nb_person && booking.nb_adult) {
            if (dataForm.length > 0) {
                return (
                    <Col md={12} className="text-center">
                        <p>
                            Votre demande de réservation a bien été prise en compte, vous pouvez procéder au paiement en
                            sélectionnant une option ci-dessous.
                        </p>

                        {["SUISSE", "Suisse"].includes(booking.apartment.address.country) ? (
                            // Cas pour la Suisse
                            <>
                                <Button
                                    variant="secondary"
                                    className="apartmentText"
                                    style={{ marginTop: "1vh", marginLeft: "0vw", marginBottom: "1vh" }}
                                    onClick={handleBankTransfertSubmit}
                                    size="sm"
                                >
                                    Payer Par Virement
                                </Button>
                            </>
                        ) : (
                            // Cas pour les autres pays
                            <>
                                <Button
                                    variant="secondary"
                                    className="apartmentText"
                                    style={{ marginTop: "1vh", marginLeft: "0vw", marginBottom: "1vh" }}
                                    onClick={handleBankTransfertSubmit}
                                    size="sm"
                                >
                                    Payer Par Virement
                                </Button>
                                <form method="POST" action={environnement.payment}>
                                    {dataForm.map((input) => input)}
                                    <Button variant='secondary' type='submit' className="apartmentText"
                                            style={{marginTop: "1vh", marginLeft: "0vw", marginBottom: "1vh"}}
                                            size="sm">Payer Par Carte Bancaire</Button>
                                </form>
                            </>
                        )}
                    </Col>
                );
            }

            return (isSubmit ? <Spinner></Spinner> :
                    <>
                        <Form.Group controlId="formBasicCheckbox" className='text-center'>
                            <Form.Check>
                                <FormCheck.Input type="checkbox" onClick={(e) => handleCglChecked(e.target.checked)}
                                                 checked={cglAccepted}/>
                                <FormCheck.Label>
                                    En cochant cette case j'accepte <a href='/CGL' target='_blank'>les conditions générales de location</a> *
                            </FormCheck.Label>
                        </Form.Check>
                        {
                            !cglAccepted ?
                                <small style={{ color: 'red' }}>Pour pouvoir réserver merci de cocher la case ci-dessus</small>
                                :
                                <></>
                        }
                    </Form.Group>
                    <Row className="d-flex justify-content-center" style={{ height: '3rem' }}>
                        <Button
                            className={cglAccepted && booking.nb_adult <= booking.nb_person ? 'reserverButtonEnabled goldenHover' : 'reserverButtonDisabled goldenHover'}
                            size="lg"
                            onClick={() => handleSubmitBooking()} disabled={cglAccepted && booking.nb_adult <= booking.nb_person ? false : true}>
                            Réserver
                        </Button>
                    </Row>
                </>
            )
        } else if (!booking.nb_person || !booking.nb_adult) {
            if (!booking.nb_person && !booking.nb_adult) {
                return (
                    <Form.Row>
                        <small style={{ color: 'red' }}>Veuillez sélectionner un nombre de personnes et d'adultes présent dans le logement</small>
                        <Form.Group id="nb_person">
                            <Form.Label>Nombre de personnes *</Form.Label>
                            <Form.Control name="nb_person" as="select" onChange={handleNumberChange}>
                                <option>Sélectionner</option>
                                {getMaxNumber('nb_person')}
                            </Form.Control>
                        </Form.Group>
                        <Form.Group id="nb_adult">
                            <Form.Label>Nombre d'adultes *</Form.Label>
                            <Form.Control name="nb_adult" as="select" onChange={handleNumberChange}>
                                <option>Sélectionner</option>
                                {getMaxNumber('nb_adult')}
                            </Form.Control>
                        </Form.Group>
                    </Form.Row>
                )
            }
            if (!booking.nb_person) {
                return (
                    <Form.Group id="nb_person">
                        <p>Nombre de d'adultes : {booking.nb_adult}</p>
                        <small style={{ color: 'red' }}>Veuillez selectionner un nombre de personnes présentes dans l'logement</small>
                        <Form.Label>Nombre de personnes *</Form.Label>
                        <Form.Control name="nb_person" as="select" onChange={handleNumberChange}>
                            <option>Selectionner</option>
                            {getMaxNumber('nb_person')}
                        </Form.Control>
                    </Form.Group>
                )
            }
            if (!booking.nb_adult) {
                return (
                    <Form.Row>
                        <Form.Group id="nb_adult">
                            <p>Nombre de personnes : {booking.nb_person}</p>
                            <small style={{ color: 'red' }}>Veuillez selectionner un nombre d'adultes présents dans l'logement</small><br />
                            <Form.Label>Nombre d'adultes *</Form.Label>
                            <Form.Control name="nb_adult" as="select" onChange={handleNumberChange}>
                                <option>Selectionner</option>
                                {getMaxNumber('nb_adult')}
                            </Form.Control>
                        </Form.Group>
                    </Form.Row>

                )
            }
        }
    }

    function getDifferenceInDays(date1, date2) {
        const diffInMs = Math.abs(date2 - date1);
        return Math.round(diffInMs / (1000 * 60 * 60 * 24));
    }

    function getacompteAmount() {
        let now = new Date();
        let begin_date = new Date(booking.begin_date_hour.replace(/\s/, 'T'));
        if (getDifferenceInDays(now, begin_date) < 15) {
            return (booking.rate).toFixed(2);
        }
        return (booking.rate / 2).toFixed(2);
    }

    let ToogleValue = 'Sélectionner un Contact';

    if (booking && booking.contact_id) {
        let contact = user.contacts.find((contact) => contact.id == booking.contact_id);
        ToogleValue = contact.first_name + ' ' + contact.last_name;
    } else if (user && user.contacts.length > 0) {
        let contact = user.contacts.find((contact) => contact.is_principal === 1);
        ToogleValue = contact.first_name + ' ' + contact.last_name;
    }

    function close() {
        setVisibility(!visibility);
        setPending(!pending);
    }

    return (
        <>
            <Button variant='secondary' className="btn-contact-result goldenHover" onClick={() => setVisibility(!visibility)}>
                {
                    props.invalidPeriod
                        ?
                        'Modifier les dates'
                        :
                        'Détails'
                }
            </Button>
            <Modal
                backdropClassName="backdrop"
                show={visibility}
                onHide={() => {
                    if (askConfirmClose) {
                        setAskConfirmVisibility(true);
                    } else {
                        close();
                    }
                }}
                size='xl'
                centered
                keyboard={true}
                style={{ zIndex: '20000' }}
            >
                <Modal.Header closeButton>
                    <Modal.Title><h2>{props.apartment.title} Ref : {props.apartment.reference}</h2></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {booking && !isSubmit ?
                        <Row className="justify-content-center">
                            <Col xs={12} className="d-flex justify-content-center mb-3">
                                <Button onClick={() => setBooking()} className='goldenHover'>Vos dates ont changé ?</Button>
                            </Col>
                            <Col md="12" lg={8}>
                                {booking.nb_person && booking.nb_adult && <>
                                    <Form.Group as={Row} className="d-flex justify-content-center align-items-center">
                                        <Form.Label column xs="7" md="8" lg="6">
                                            Nombre de personnes :
                                        </Form.Label>
                                        <Col xs="5" md="4" lg='2'>
                                            <Form.Control name="nb_person" as="select" onChange={handleNumberChange} style={{ width: 'unset' }}>
                                                {getMaxNumber('nb_person')}
                                            </Form.Control>
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="d-flex justify-content-center align-items-center">
                                        <Form.Label column xs="7" md="8" lg="6">
                                            Nombre d'adultes :
                                        </Form.Label>
                                        <Col xs="5" md="4" lg='2'>
                                            <Form.Control name="nb_adult" style={booking.nb_person < booking.nb_adult ? { color: 'red', width: 'unset' } : { width: 'unset' }} as="select" onChange={handleNumberChange}>
                                                {getMaxNumber('nb_adult')}
                                            </Form.Control>
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="d-flex justify-content-center align-items-center">
                                        <Form.Label column xs="7" md="8" lg="6">
                                            Nombre d'enfants :
                                        </Form.Label>
                                        <Col xs="5" md="4" lg='2'>
                                            <Form.Control name="nb_child" as="select" onChange={null} style={{ width: 'unset' }}>
                                                {getMaxNumber('nb_adult', false)}
                                            </Form.Control>
                                        </Col>
                                    </Form.Group>
                                </>
                                }
                            </Col>
                            <Col md={12} lg={4} className='text-lg-left text-center'>
                                <p>Arrivée : <strong>{new Date(booking.begin_date_hour.replace(/\s/, 'T')).toLocaleDateString('fr-FR', optionsDates)}</strong></p>
                                <p>Depart : <strong>{new Date(booking.end_date_hour.replace(/\s/, 'T')).toLocaleDateString('fr-FR', optionsDates)}</strong></p>
                                <p>Nuitées : <strong>{getDifferenceInDays(new Date(booking.begin_date_hour.replace(/\s/, 'T')), new Date(booking.end_date_hour.replace(/\s/, 'T')))}</strong></p>
                            </Col>
                            <Col md={12} lg={8}>
                                <Table striped bordered size="sm">
                                    <thead>
                                        <tr className='text-center'>
                                            <th>Détail</th>
                                            <th>Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Tarif du séjour</td>
                                            <td>{(booking.rate).toFixed(2)} {booking.apartment.address.country === "SUISSE" || booking.apartment.address.country === "Suisse" ? 'CHF' : "€" }</td>
                                        </tr>
                                        <tr>
                                            <td>acompte</td>
                                            <td>{getacompteAmount()} {booking.apartment.address.country === "SUISSE" || booking.apartment.address.country === "Suisse" ? 'CHF' : "€" }</td>
                                        </tr>
                                    </tbody>
                                </Table>
                                <Table striped bordered size="sm">
                                    <tr>
                                        <td>Dépot de garantie <small><strong>(dépot restitué dans les 15 jours après
                                            votre séjour)</strong></small></td>
                                        <td>
                                            {booking.bail_amount || booking.bail?.amount ? (
                                                <>
                                                    {booking.bail_amount
                                                        ? booking.bail_amount.toFixed(2)
                                                        : booking.bail.amount.toFixed(2)}{" "}
                                                    {["SUISSE", "Suisse"].includes(booking.apartment.address.country) ? 'CHF' : '€'}
                                                </>
                                            ) : null}

                                            {bailBooking && (
                                                <>
                                                    <span>
                                                        {bailBooking.toFixed(2)}{" "}
                                                        {["SUISSE", "Suisse"].includes(booking.apartment.address.country) ? 'CHF' : '€'}
                                                    </span>
                                                </>
                                            )}
                                        </td>
                                    </tr>
                                </Table>
                            </Col>
                            <Col md={12} lg={4} className='d-flex justify-content-center align-items-center'>
                                <Row>
                                    <Col xs={12} className='d-flex justify-content-center align-items-center'>
                                        {user && dataForm.length < 1 && user.is_society ?
                                            <Form.Group id="contact_id">
                                                <Form.Label>Personne à contacter *</Form.Label>
                                                <small style={{ color: 'red' }}><strong>{bookingError['contact_id']}</strong></small>
                                                <Dropdown>
                                                    <Dropdown.Toggle variant="secondary" id="dropdown-flags">
                                                        {ToogleValue}
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        {user && user.contacts.length > 0 ? user.contacts.map((contact) => {
                                                            if (contact.address) {
                                                                return <Dropdown.Item name="contact_id" value={contact.id} onClick={(e) => handleNumberChange(e)}> {contact.first_name + ' ' + contact.last_name}</Dropdown.Item>
                                                            }
                                                            return (<Dropdown.Item name="contact_id" value={contact.id} onClick={(e) => handleNumberChange(e)} disabled={true}> {contact.first_name + ' ' + contact.last_name} <FcHighPriority /></Dropdown.Item>)
                                                        })
                                                            : <Dropdown.Item disabled={true}>Veuillez renseigner des informations de contact dans votre espace personnel <FcHighPriority /></Dropdown.Item>}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </Form.Group>
                                            :
                                            <></>}
                                    </Col>
                                    <Col className='d-flex flex-column align-items-center'>
                                        {getButton()}
                                    </Col>
                                </Row>
                            </Col>
                            <Col sm={12} md={12} className='d-flex justify-content-center'>
                                {getForm()}
                            </Col>
                        </Row>
                        :
                        <>
                            <Col xs={12} className="d-flex justify-content-center">
                                {indispo && <p style={{ color: 'red' }} className='text-center'>Logement non disponible pour les dates entrées</p>}
                            </Col>
                            {isSubmit ?
                                <SkeletonContent />
                                :
                                <>
                                    <DateRange usage='admin' changeValue={changeValue} apartment={props.apartment.id} />
                                    <Row noGutters>
                                        <Col xs={12} className="d-flex justify-content-center">
                                            <Button className='goldenHover mt-4' onClick={() => setPending(true)}>Voir la disponibilité</Button>
                                        </Col>
                                    </Row>
                                </>
                            }
                        </>
                    }
                </Modal.Body>
            </Modal>
            {
                askConfirmVisibility &&
                <Modal
                    backdropClassName="backdrop"
                    show={askConfirmVisibility}
                    onHide={() => {
                        setAskConfirmVisibility(false);
                    }}
                    centered
                    backdrop='static'
                    style={{ zIndex: '20001' }}>
                    <Modal.Header>
                        <Modal.Title>
                            Confirmation
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Attention vous êtes sur le point de fermer la fenêtre de paiement merci de confirmer cette action
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant='success'
                            onClick={() => {
                                setAskConfirmVisibility(false);
                                close();
                            }}>
                            Confirmer
                        </Button>
                        <Button
                            variant='danger'
                            onClick={() => setAskConfirmVisibility(false)}>
                            Annuler
                        </Button>
                    </Modal.Footer>
                </Modal>
            }
        </>)
}

const mapStateToProps = state => {
    return {
        param: getParam(state),
        beginDate: getBeginDate(state),
        endDate: getEndDate(state),
    };
}

export default connect(mapStateToProps)(ApartmentModal);