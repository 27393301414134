import { Col, Card, Row, Image, Button } from 'react-bootstrap';
import { BsPersonFill } from 'react-icons/bs';
import { MdOutlineBedroomParent, MdOpenInNew } from 'react-icons/md';
import { useDispatch } from "react-redux";
import instanceOfAxios from '../../../app/axios/axios';
import { show } from '../../../components/admin/Alerter/alerterSlice';

export default function ResultCard({ apartment, url, setIsOverID, nightPrice, inFavorite, reloadFav }) {
    const dispatch = useDispatch();

    function nbRoom() {
        let nbRoom = JSON.parse(apartment.rooms_details).chambre
        if (nbRoom == 0) {
            return 'Studio';
        } else {
            return nbRoom > 1 ? nbRoom + '\nChambres' : nbRoom + '\nChambre';
        }
    }

    function addFavori() {
        instanceOfAxios.get('addFavori/' + apartment.id)
            .then(res => {
                dispatch(show({ message: res.data.message, variant: 'success' }));
                reloadFav(true);
            })
            .catch(err => {
                if (err.response.status == 401) {
                    dispatch(show({ message: 'Vous devez être connecté', variant: 'danger' }));
                } else {
                    dispatch(show({ message: err.response.data.message, variant: 'danger' }));
                }
            })
    }

    return (
        <Card onMouseOver={() => setIsOverID(apartment.id)} onMouseOut={() => setIsOverID(0)} style={{ marginBottom: '10px' }}>
            <Row>
                <Col md={12} xl={6} className="d-flex align-items-center justify-content-center">
                    <a target='_blank' href={url}>
                        <Image loading='lazy' src={apartment.principal_picture_url ? apartment.principal_picture_url.medium : ''} className="apartment-list-image" fluid />
                    </a>
                    <div className='divAddFavResult'>
                        <svg className='addFavResult' onClick={addFavori} xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
                            <g id="favoris" transform="translate(-59.371 -927)">
                                <g id="Ellipse_6" data-name="Ellipse 6" transform="translate(59.371 927)" fill="#fff" stroke="#fff" stroke-width="2">
                                    <circle cx="15" cy="15" r="15" stroke="none" />
                                    <circle cx="15" cy="15" r="14" fill="none" />
                                </g>
                                <path id="Icon_feather-heart" data-name="Icon feather-heart" d="M18.054,5.812a4.485,4.485,0,0,0-6.344,0l-.864.864-.864-.864a4.486,4.486,0,1,0-6.344,6.344l.864.864,6.344,6.344L17.19,13.02l.864-.864a4.485,4.485,0,0,0,0-6.344Z" transform="translate(63.396 930.165)" fill={inFavorite ? '#B7A57E' : 'none'} stroke="#b7a57e" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                            </g>
                        </svg>
                    </div>
                </Col>
                <Col md={12} xl={6} style={{ marginBottom: '1vh', paddingLeft: 'unset', paddingRight: 'unset' }} className="d-flex align-items-center">
                    <Row>
                        <Col xs={12} className='d-flex align-items-center justify-content-center justify-content-xl-start card-search-title'>
                            <h2 className='text-lg-left text-sm-center' style={{color: 'black' }}>{apartment.type} Réf : <strong>{apartment.reference}</strong></h2>
                        </Col>
                        <Col xs={12} sm={6} className='d-flex align-items-center justify-content-center justify-content-xl-start mb-3'>
                            <BsPersonFill style={{ height: '25px', width: '25px', marginRight: '5px' }} />
                            <p className='text-center' style={{ margin: 'unset' }}>
                                {apartment.nb_person} {apartment.nb_person > 1 ? 'Personnes' : 'Personne'}
                            </p>
                        </Col>
                        <Col xs={12} sm={6} className='d-flex align-items-center justify-content-center justify-content-xl-start mb-3'>
                            <MdOutlineBedroomParent style={{ height: '25px', width: '25px', marginRight: '5px' }} />
                            <p className='text-center' style={{ margin: 'unset' }}>
                                {nbRoom()}
                            </p>
                        </Col>
                        <Col xs={12} sm={12} className='d-flex align-items-center justify-content-center justify-content-xl-start'>
                            <p>{nightPrice == apartment.minRate ? 'Prix : à partir de ' : 'Prix : '}{(parseFloat(nightPrice)).toFixed(2)} {apartment.address.country === "SUISSE" || apartment.address.country === "Suisse" ? 'CHF' : "€" } la nuit</p>
                        </Col>
                        <Col xs={12} sm={12} className='d-flex justify-content-center justify-content-xl-start'>
                            <a target='_blank' href={url}><Button className='goldenHover'>Découvrir <MdOpenInNew /></Button></a>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Card>
    )
}