import React from 'react';
import { Container, Col, Row, Image } from 'react-bootstrap';
import './statics.scss';

export default function TorgonWinter() {
    return (
        <Container
            style={{ paddingTop: '5vh', paddingBottom: '50px', maxWidth: '80vw', height: 'unset' }}
            className="noPadding"
        >
            <Row>
                <Col sm={12}>
                    <h2 style={{ textDecoration: 'underline 1px', textAlign: 'center', color: '#B7A57E' }}>
                        Torgon l’hiver
                    </h2>
                    <p className="text-justify text-center mt-4">
                        Cet hiver, <strong>Torgon</strong> vous invite à vivre une expérience unique au cœur des Alpes. Niché
                        entre montagnes et lac, ce village pittoresque offre des panoramas époustouflants et une multitude
                        d'activités hivernales pour tous les goûts. Que vous soyez amateur de <strong>ski</strong>, de
                        <strong> raquettes</strong>, ou en quête de moments ludiques en famille, Torgon saura combler vos
                        attentes. Explorez la région à travers des sports de glisse, des randonnées enneigées ou encore des
                        activités familiales, tout en profitant de la beauté saisissante de l’hiver alpin.
                    </p>
                </Col>
            </Row>

            <hr className="page-separator" />

            <Row className="mt-4 bg-light">
                <Col sm={12} md={5}>
                    <Row>
                        <Col md={12} className="pb-2">
                            <Image
                                loading="lazy"
                                src="/images/image_page/torgon_hiver/glisse.jpg"
                                alt="Sports de Glisse"
                                fluid
                                className="rounded"
                                style={{
                                    width: '100%',
                                    height: '200px', // Hauteur fixe
                                    objectFit: 'cover', // Maintenir un recadrage propre
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <Image
                                loading="lazy"
                                src="/images/image_page/torgon_hiver/2.jpg"
                                alt="Sports de Glisse"
                                fluid
                                className="rounded"
                                style={{
                                    width: '100%',
                                    height: '130px', // Hauteur fixe
                                    objectFit: 'cover', // Maintenir un recadrage propre
                                }}
                            />
                        </Col>

                        <Col md={6}>
                            <Image
                                loading="lazy"
                                src="/images/image_page/torgon_hiver/snow.jpg"
                                alt="Sports de Glisse"
                                fluid
                                className="rounded"
                                style={{
                                    width: '100%',
                                    height: '130px', // Hauteur fixe
                                    objectFit: 'cover', // Maintenir un recadrage propre
                                }}
                            />
                        </Col>
                    </Row>
                </Col>
                <Col sm={12} md={7} className="p-3">
                    <h3>Sports de Glisse</h3>
                    <p className="text-justify">
                        À 1 085 m d’altitude, Torgon est la porte d’entrée suisse des Portes du Soleil, un domaine skiable de
                        600 km de pistes. La station propose <strong>11 pistes</strong> entre 1 150 m et 2 001 m, parfaites
                        pour le ski et le snowboard. Avec <strong>7 remontées mécaniques</strong> et un accès direct aux
                        stations françaises, profitez de descentes variées et de paysages spectaculaires !
                    </p>
                </Col>
            </Row>

            <Row className="mt-4 bg-light">
                <Col sm={12} md={7} className="p-3">
                    <h3>Activités en Pleine Nature</h3>
                    <p className="text-justify">
                        Plongez dans l'aventure à Torgon avec le <strong>ski de randonnée</strong> et les
                        <strong> raquettes</strong> ! Explorez <strong>3 parcours</strong> balisés <strong>(15 km)</strong> pour le ski et <strong>4
                        itinéraires</strong> en raquettes <strong>(21,3 km)</strong> à travers des paysages enneigés. Une expérience hivernale unique, alliant défis et
                        moments paisibles au <strong>cœur des Alpes</strong> !
                    </p>
                </Col>
                <Col sm={12} md={5}>
                    <Image
                        loading="lazy"
                        src="/images/image_page/torgon_hiver/rando.jpg"
                        alt="Activités en Pleine Nature"
                        fluid
                        className="rounded"
                        style={{
                            width: '100%',
                            height: '250px', // Hauteur fixe
                            objectFit: 'cover', // Maintenir un recadrage propre
                        }}
                    />
                </Col>
            </Row>

            <Row className="mt-4 bg-light">
                <Col sm={12} md={5}>
                    <Row>
                        <Col md={6} className="pb-2">
                            <Image
                                loading="lazy"
                                src="/images/image_page/torgon_hiver/4.jpg"
                                alt="Sports de Glisse"
                                fluid
                                className="rounded"
                                style={{
                                    width: '100%',
                                    height: '130px', // Hauteur fixe
                                    objectFit: 'cover', // Maintenir un recadrage propre
                                }}
                            />
                        </Col>

                        <Col md={6}>
                            <Image
                                loading="lazy"
                                src="/images/image_page/torgon_hiver/5.jpg"
                                alt="Sports de Glisse"
                                fluid
                                className="rounded"
                                style={{
                                    width: '100%',
                                    height: '130px', // Hauteur fixe
                                    objectFit: 'cover', // Maintenir un recadrage propre
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <Image
                                loading="lazy"
                                src="/images/image_page/torgon_hiver/parapente-les-menuires.jpg"
                                alt="parapente-les-menuires"
                                fluid
                                className="rounded"
                                style={{
                                    width: '100%',
                                    height: '200px', // Hauteur fixe
                                    objectFit: 'cover', // Maintenir un recadrage propre
                                }}
                            />
                        </Col>
                    </Row>
                </Col>
                <Col sm={12} md={7} className="p-3">
                    <h3>Divertissements & Loisirs</h3>
                    <p className="text-justify">
                        Torgon offre une variété d'activités hivernales pour les enfants : le <strong>tir à l'arc hivernal</strong>{' '}
                        pour tester leur précision, les <strong>Escape Games</strong> pour des aventures en famille et des défis
                        amusants, ainsi que la luge pour des descentes excitantes sur les pistes enneigées ! Des moments de rire
                        et de partage garantis pour toute la famille !
                    </p>
                </Col>
            </Row>

            <Row className="mt-4 bg-light">
                <Col xs={12} md={7} className="p-3">
                    <h3>Bien-être et détente</h3>
                        <p className="text-justify">
                            Offrez-vous un moment de relaxation absolue aux <strong>Thermes de Lavey</strong>, à deux pas de <strong>Torgon</strong>
                            ! Plongez dans des bassins d’eau <strong>naturellement chaude</strong>, entourés par un décor alpin enchanteur. Profitez des bains bouillonnants, des saunas et des hammams pour une expérience de bien-être inégalée. Que ce soit après une journée de randonnée ou simplement pour s’évader du quotidien, laissez-vous envelopper par la <strong>chaleur
                                apaisante</strong> des <strong>eaux thermales</strong> les plus chaudes de Suisse. Un véritable havre de paix au cœur des montagnes !
                        </p>
                </Col>
                <Col sm={12} md={5}>
                    <Image
                        loading="lazy"
                        src="/images/image_page/torgon_hiver/bains-slider.jpg"
                        alt="Eaux thermales"
                        fluid
                        className="rounded"
                        style={{
                            width: '100%',
                            height: '250px', // Hauteur fixe
                            objectFit: 'cover', // Maintenir un recadrage propre
                        }}
                    />
                </Col>
            </Row>

            <Row className="mt-4 align-items-center bg-light">
                <Col xs={12} md={5}>
                    <Image
                        loading="lazy"
                        src="/images/image_page/torgon_hiver/passerel.jpg"
                        alt="Pont suspendu à Torgon"
                        fluid
                        className="rounded"
                        style={{
                            width: '100%',
                            height: '250px', // Hauteur fixe
                            objectFit: 'cover', // Maintenir un recadrage propre
                        }}
                    />
                </Col>
                <Col xs={12} md={7} className="p-3">
                    <h3>Visite</h3>
                    <p className="text-justify">
                        Partez à l’aventure avec le <strong>géocaching</strong> à Torgon, une activité ludique où vous
                        résolvez des énigmes pour découvrir des <strong>trésors cachés</strong> tout en explorant la
                        beauté naturelle des environs. Profitez également du <strong>pont suspendu</strong>, qui vous
                        offre des <strong>vues imprenables</strong> sur la vallée au <strong>cœur des montagnes</strong>,
                        pour des souvenirs mémorables en famille ou entre amis.
                    </p>
                </Col>
            </Row>

            {/* Ligne 1 IMAGE*/}
            <Row className="mb-4 mt-4 pt-4 bg-light">
                <Col md={6}>
                    <Image
                        src="/images/image_page/torgon_hiver/destination-torgon-winter.jpg"
                        alt="Image 1"
                        fluid
                        className="rounded "
                        style={{
                            width: '100%',
                            height: '250px', // Hauteur fixe
                            objectFit: 'cover', // Maintenir un recadrage propre
                        }}
                    />
                </Col>
                <Col md={6}>
                    <Image
                        src="/images/image_page/torgon_hiver/torgon_orig_28291.jpg"
                        alt="Image 2"
                        fluid
                        className="rounded"
                        style={{
                            width: '100%',
                            height: '250px', // Hauteur fixe
                            objectFit: 'cover', // Maintenir un recadrage propre
                        }}
                    />
                </Col>
            </Row>

            {/* Ligne 2 */}

            <Row className="mb-4 mt-4">
                {/* Première image (petite) */}
                <Col md={4} className="d-flex justify-content-center align-items-center">
                    <Image
                        src="/images/image_page/torgon_hiver/Jorette-Bruecke-Torgon.jpg"

                        alt="Image 1"
                        className="rounded"
                        style={{
                            width: '100%',
                            height: '150px', // Petite taille
                            objectFit: 'cover',
                        }}
                    />
                </Col>

                {/* Image du milieu (grande) */}
                <Col md={4} className="d-flex justify-content-center align-items-center">
                    <Image
                        src="/images/image_page/torgon_hiver/activity-skisnowboard_torgon_1.jpg"
                        alt="Image du Milieu"
                        className="rounded"
                        style={{
                            width: '100%',
                            height: '150px', // Plus grande hauteur
                            objectFit: 'cover',
                        }}
                    />
                </Col>

                {/* Troisième image (petite) */}
                <Col md={4} className="d-flex justify-content-center align-items-center">
                    <Image
                        src="/images/image_page/torgon_hiver/ski.webp"
                        alt="Image 3"
                        className="rounded"
                        style={{
                            width: '100%',
                            height: '150px', // Petite taille
                            objectFit: 'cover',
                        }}
                    />
                </Col>
            </Row>
        </Container>
    );
}
